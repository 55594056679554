import { Track } from "@models/track";

export type PlayerState = {
	queue: Track[];
	virtualQueue: Track[];
	currentTrack?: Track;
	shouldPlay: boolean;
	previouslyPlayedTracks: (number | string)[];
};

export interface PlayerActions {
	clearQueue(): void;
	setQueue(tracks: Track[]): void;
	clearVirtualQueue(): void;
	setVirtualQueue(tracks: Track[]): Track[];
	selectTrack(track: Track): void;
	addTrack(track: Track): void;
	addTracks(tracks: Track[]): void;
	playTrack(track: Track): void;
	setPlay(shouldPlay: boolean): void;
	setPlayedHistory(previouslyPlayedTracks: (number | string)[]): void;
}

export const CLEAR_QUEUE = "@PLAYER/CLEAR_QUEUE";
export const SET_QUEUE = "@PLAYER/SET_QUEUE";
export const CLEAR_VIRTUAL_QUEUE = "@PLAYER/CLEAR_VIRTUAL_QUEUE";
export const SET_VIRTUAL_QUEUE = "@PLAYER/SET_VIRTUAL_QUEUE";
export const SELECT_TRACK = "@PLAYER/SELECT_TRACK";
export const ADD_TRACK = "@PLAYER/ADD_TRACK";
export const ADD_TRACKS = "@PLAYER/ADD_TRACKS";
export const PLAY_TRACK = "@PLAYER/PLAY_TRACK";
export const SET_PLAY = "@PLAYER/SET_PLAY";
export const SET_PLAYED_HISTORY = "@PLAYER/SET_PLAYED_HISTORY";

export type ClearQueueAction = {
	type: typeof CLEAR_QUEUE;
};

export type SetQueueAction = {
	type: typeof SET_QUEUE;
	payload: {
		tracks: Track[];
	};
};

export type ClearVirtualQueueAction = {
	type: typeof CLEAR_VIRTUAL_QUEUE;
};

export type SetVirtualQueueAction = {
	type: typeof SET_VIRTUAL_QUEUE;
	payload: {
		tracks: Track[];
	};
};

export type SelectTrackAction = {
	type: typeof SELECT_TRACK;
	payload: {
		track: Track;
	};
};

export type AddTrackAction = {
	type: typeof ADD_TRACK;
	payload: {
		track: Track;
	};
};

export type AddTracksAction = {
	type: typeof ADD_TRACKS;
	payload: {
		tracks: Track[];
	};
};

export type PlayTrackAction = {
	type: typeof PLAY_TRACK;
	payload: {
		track: Track;
	};
};

export type SetPlayAction = {
	type: typeof SET_PLAY;
	payload: {
		shouldPlay: boolean;
	};
};

export type SetPlayedHistoryAction = {
	type: typeof SET_PLAYED_HISTORY;
	payload: {
		previouslyPlayedTracks: [];
	};
};

export type ActionTypes =
	| ClearQueueAction
	| SetQueueAction
	| ClearVirtualQueueAction
	| SetVirtualQueueAction
	| SelectTrackAction
	| AddTrackAction
	| AddTracksAction
	| PlayTrackAction
	| SetPlayAction
	| SetPlayedHistoryAction;
