import { DEFAULT_AUDIO_FORMAT } from "@lib/constants";
import { useSessionContext } from "@lib/context/session";
import { getAudioFormatsQuery } from "@lib/network/audio-formats";
import {
	getMyCartItemsQuery,
	getMyCartQuery,
	getMyCartsQuery,
} from "@lib/network/my";
import { getDefaultCartPageSize } from "@lib/utils/carts";
import { Cart, CartDetails, CartItem } from "@models/Cart";
import { Audioformat } from "@models/audio-format";
import { PaginatedResponse } from "@models/generics";
import { Release } from "@models/release";
import { Track } from "@models/track";
import { QueryClient, useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import {
	ReactElement,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";

import { createActions } from "./actions";

import { CartActions, CartState } from "./types";

const initialState: CartState = {
	audioFormats: {},
	carts: {},
	cartDetails: {},
	sortedCarts: [],
	loading: true,
	defaultAudioFormat: DEFAULT_AUDIO_FORMAT.id,
};

interface CartContextValue {
	state: CartState;
	refetchCarts: () => void;
	getSingleCartWithItems: (cartId: number) => void;
	isCartSelectorDisabled: boolean;
	setCartSelectorDisabled: (value: boolean) => void;
}

export const CartContextDefaultValue = {
	state: initialState,
	refetchCarts: () => { },
	getSingleCartWithItems: () => { },
	isCartSelectorDisabled: false,
	setCartSelectorDisabled: () => { },
};

export const defaultCartItemParams = (cartName?: string) => ({
	page: 1,
	per_page: getDefaultCartPageSize(cartName),
});

export const CartContext = createContext<CartContextValue>(
	CartContextDefaultValue,
);

export const useCart = (): CartContextValue => {
	const ctx = useContext(CartContext);
	if (!ctx) {
		throw new Error("useCart must be used within CartProvider");
	}
	return ctx;
};

export const CartActionsContext = createContext<CartActions | null>(null);

export const useCartActions = (): CartActions => {
	const ctx = useContext(CartActionsContext);
	if (!ctx) {
		throw new Error("useCartActions must be used within CartActionsContext");
	}
	return ctx;
};

export type CartProviderProps = {
	children: ReactNode;
	state?: CartState;
	refetchCarts?: () => void;
};

const MY_CARTS_BASE_KEY = "/my/carts/";
export const MY_CART_BASE_KEY = "my-cart-items";

export const invalidateCart = async (queryClient: QueryClient, cartId?: number | string, itemsType?: string) => {
	const cartsKey = [`${MY_CARTS_BASE_KEY}${cartId}/`];
	await queryClient.invalidateQueries(cartsKey);
	if (itemsType) {
		await queryClient.invalidateQueries({
			predicate: (query: any) =>
				query.queryKey[0] === MY_CART_BASE_KEY &&
				query.queryKey[1]?.id === cartId &&
				query.queryKey[1]?.itemsType.includes(itemsType),
		});
	} else {
		await queryClient.invalidateQueries({
			predicate: (query: any) =>
				query.queryKey[0] === MY_CART_BASE_KEY &&
				query.queryKey[1]?.id === cartId,
		});
	}
};

export const CartProvider: React.FC<CartProviderProps> = ({
	children,
	state: propInitialState,
}: CartProviderProps): ReactElement => {
	const [state, setState] = useState(propInitialState || initialState);
	const [isCartSelectorDisabled, setCartSelectorDisabled] = useState<boolean>(false);
	const { getAccessToken, getIsSessionValid, session } = useSessionContext();
	const queryClient = useQueryClient();
	const isSessionValid = getIsSessionValid({ isAnonAllowed: true });
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });
	const accessToken = getAccessToken();
	const refetchCarts = () => {
		myCarts.refetch();
	};

	const getSingleCartWithItems = async (cartId: number, itemType?: "track" | "release") => {
		const params = defaultCartItemParams(state.carts[cartId]?.name);
		await invalidateCart(queryClient, cartId, itemType);

		const updatedCartSummary = queryClient.getQueryData<CartDetails>([`${MY_CARTS_BASE_KEY}${cartId}/`]);
		const updatedTracks = queryClient.getQueryData<
			PaginatedResponse<CartItem<Track>>
		>([
			MY_CART_BASE_KEY,
			{
				id: cartId,
				itemsType: "tracks",
				page: params.page,
				per_page: params.per_page,
				isCartPage: false,
			},
		]);
		const updatedReleases = queryClient.getQueryData<
			PaginatedResponse<CartItem<Release>>
		>([
			MY_CART_BASE_KEY,
			{
				id: cartId,
				itemsType: "releases",
				page: params.page,
				per_page: params.per_page,
				isCartPage: false,
			},
		]);

		const stateCopy = { ...state };

		const cartDetails = stateCopy.cartDetails;
		if (updatedCartSummary) {
			updatedCartSummary.tracks = updatedTracks?.results || [];
			updatedCartSummary.releases = updatedReleases?.results || [];
			cartDetails[cartId] = updatedCartSummary;
		}
	};

	const actions = createActions(
		state,
		session,
		setState,
		refetchCarts,
		getSingleCartWithItems,
	);

	const defaultAudioFormat = DEFAULT_AUDIO_FORMAT.id;

	const myCarts = useQuery<Array<Cart>>({
		...getMyCartsQuery({ accessToken }),
		enabled: isLoggedInSessionValid,
	});

	const myCartsData = myCarts?.data || ([] as Cart[]);

	const audioFormatsResults = useQuery({
		...getAudioFormatsQuery({ accessToken }),
		staleTime: Infinity,
		cacheTime: Infinity,
		enabled: isSessionValid,
	});

	const cartDataResults = useQueries<Array<CartDetails>>({
		queries: myCartsData.map((cart) => ({
			...getMyCartQuery({ cartId: cart.id, accessToken }),
			staleTime: Infinity,
			cacheTime: Infinity,
			enabled: isLoggedInSessionValid,
		})),
	});

	const cartDataResultsReady =
		cartDataResults.length > 0 && !cartDataResults.some((query) => !query.data);

	const cartTracksResults = useQueries<
		Array<PaginatedResponse<CartItem<Track>[]>>
	>({
		queries: myCartsData.map((cart) => ({
			...getMyCartItemsQuery(
				cart.id,
				"tracks",
				defaultCartItemParams(cart.name),
				accessToken,
			),
			staleTime: Infinity,
			cacheTime: Infinity,
			enabled: cartDataResultsReady,
		})),
	});

	const cartReleasesResults = useQueries<
		Array<PaginatedResponse<CartItem<Release>[]>>
	>({
		queries: myCartsData.map((cart) => ({
			...getMyCartItemsQuery(
				cart.id,
				"releases",
				defaultCartItemParams(cart.name),
				accessToken,
			),
			staleTime: Infinity,
			cacheTime: Infinity,
			enabled: cartDataResultsReady,
		})),
	});

	const cartTracksResultsReady =
		cartTracksResults.length > 0 &&
		!cartTracksResults.some((query) => !query.data);

	const cartReleasesResultsReady =
		cartReleasesResults.length > 0 &&
		!cartReleasesResults.some((query) => !query.data);

	useEffect(() => {
		if (cartDataResultsReady && audioFormatsResults.data) {
			const carts: Record<number, Cart> = {};
			myCartsData.map((cart: Cart) => {
				if (cart) {
					carts[cart.id] = cart;
				}
			});

			const cartDetails: Record<number, CartDetails> = {};
			cartDataResults.map((cartDataItem: any) => {
				const cartData = cartDataItem.data;
				if (cartData) {
					cartDetails[cartData.id] = cartData;
				}
			});

			const audioFormats: Record<number, Audioformat> = {};
			audioFormatsResults?.data?.results?.map((audioFormat: Audioformat) => {
				if (audioFormat) {
					audioFormats[audioFormat.id] = audioFormat;
				}
			});

			const sortedCarts = Object.values(cartDetails).sort((a, b) => {
				if (a.name === "cart") {
					return -1;
				} else if (b.name === "cart") {
					return 1;
				} else if (a.name === "hold-bin") {
					return -1;
				} else if (b.name === "hold-bin") {
					return 1;
				} else {
					if (a.name > b.name) return 1;
					if (a.name < b.name) return -1;
				}
				return 0;
			});

			if (isEqual(state, initialState)) {
				setState({
					...state,
					loading: false,
					audioFormats: audioFormats,
					carts: carts,
					cartDetails: cartDetails,
					sortedCarts: sortedCarts,
					defaultAudioFormat: defaultAudioFormat,
				});
			}
		}
	}, [cartDataResults]);

	useEffect(() => {
		const cartDetails = state.cartDetails;
		if (cartTracksResultsReady) {
			cartTracksResults.map((item: any, index) => {
				const tracks = item?.data?.results || [];
				if (cartDetails[myCartsData[index].id]) {
					cartDetails[myCartsData[index].id].tracks = tracks;
				}
			});
		}

		if (cartReleasesResultsReady) {
			cartReleasesResults.map((item: any, index) => {
				const releases = item?.data?.results || [];
				if (cartDetails[myCartsData[index].id]) {
					cartDetails[myCartsData[index].id].releases = releases;
				}
			});
		}

		setState({ ...state, cartDetails: cartDetails });
	}, [cartTracksResultsReady, cartReleasesResultsReady]);

	return (
		<CartContext.Provider
			value={{ state, refetchCarts, getSingleCartWithItems, isCartSelectorDisabled, setCartSelectorDisabled }}
		>
			<CartActionsContext.Provider value={actions}>
				{children}
			</CartActionsContext.Provider>
		</CartContext.Provider>
	);
};
