import { HEADER_HEIGHT } from "@lib/css";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-bewteen;
  height: ${HEADER_HEIGHT};
  width: 100%;
  font-size: 14px;
  background: ${(props) => props.theme.colors.neutrals.primary[900]};
  border-bottom: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.divider1};
  ul > {
    li {
      display: inline-flex;
      vertical-align: middle;
      padding: 0px 8px;
    }
  }
  span {
    display: flex;
    flex-direction: row;
    > img {
      margin-right: 4px;
    }
    svg {
      stroke: ${(props) => props.theme.colors.neutrals.primary.graytext};
    }

    &:hover svg {
      stroke: ${(props) => props.theme.colors.green[300].accent};
      &.rotatable {
        transform: rotate(180deg);
        margin-top: 5px;
      }
    }
  }
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  flex-direction: row;
  width: 100%;
`;

export const DesktopHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;

  svg {
    stroke: ${(props) => props.theme.colors.neutrals.primary.graytext};
  }

  ul {
    .dropdown_menu {
      animation: growDown 300ms ease-in-out forwards;
      transform-origin: top center;
    }
    li {
      line-height: 20px;

      padding: 20px 7px;
      cursor: pointer;
      span.white {
        a {
          padding-left: 5px;
        }
        svg {
          stroke: ${(props) => props.theme.colors.neutrals.primary.white};
        }
      }

      span.white:hover {
        color: ${(props) => props.theme.colors.green[300].accent};

        svg {
          stroke: ${(props) => props.theme.colors.green[300].accent};
        }
      }

      &.header_item:hover div {
        display: flex;
      }
    }
    .header_item {
      .svg_wrapper {
        display: flex;
        align-items: center;
        height: 100%;

        img {
          border-radius: 50%;
        }
      }
    }
  }

  span {
    align-items: center;
    > img {
      margin-left: 2px;
      margin-bottom: 1px;
    }
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

export const LeftRegion = styled.ul`
  min-width: 81px;
  margin-left: 16px;

  @media ${(props) => props.theme.device.md} {
    width: 100%;
  }

  @media ${(props) => props.theme.device.xl} {
    min-width: 712px;
  }
`;
export const RightRegion = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  /* min-width: 310px; */
  padding-right: 20px;
  .svg_wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  div.account_avatar {
    width: 32px;
    height: 32px;
    img {
      border-radius: 50%;
    }
  }

  li {
    line-height: 20px;
    padding: 20px 7px;
  }

  span {
    align-items: center;
    > img {
      margin-left: 2px;
      margin-bottom: 1px;
    }
  }

  span.white {
    a {
      padding-left: 5px;
    }
    svg {
      stroke: ${(props) => props.theme.colors.neutrals.primary.white};
    }
  }

  span.white:hover {
    color: ${(props) => props.theme.colors.green[300].accent};

    svg {
      stroke: ${(props) => props.theme.colors.green[300].accent};
    }
  }

  @media ${(props) => props.theme.device.md} {
    min-width: 152px;
    max-width: 790px;
  }

  @media ${(props) => props.theme.device.xl} {
    li.search:first-child {
      width: 100%;
      max-width: 454px;
      padding: 0px;
    }
    li.subscription_button {
      min-width: 141px;
    }
  }
`;

export const MenuIcon = styled.img`
  height: 17.25px;
  width: 21px;
`;

export const BeatportLogoSmall = styled.img`
  height: 28px;
  width: 22px;
`;

export const BeatportLogoLarge = styled.img`
  height: 29px;
  width: 82px;
  margin-right: 20px;
`;

export const HeaderIconMd = styled.img`
  height: 24px;
  width: 24px;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const HeaderSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 454px;

  > button > svg {
    margin-right: 20px;
    height: 20px;
    stroke-width: 2px;
  }
`;

export const HeaderSearch = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  border-radius: 4px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};

  &:focus-within {
    border: 1px solid ${(props) => props.theme.colors.green[300].accent};
    box-shadow: ${(props) => props.theme.boxShadows.green["1"]};
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  div:first-child {
    display: flex;
    align-items: center;
    pointer-events: none;
    margin-left: 5px;
  }

  button {
    display: flex;
    align-items: center;
    margin-right: 10px;
    z-index: 100000;
  }

  img {
    margin-left: 5px;
    margin-top: 1px;
    height: 16px;
    width: 16px;
  }
  
  input {
    height: 32px;
    margin-left: 5px;
    width: 100%;
    background: inherit;
    outline: none;
    border: none;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${(props) => props.theme.colors.neutrals.primary.graytext};
    }
  }
`;

export const GenreDropdownMenu = styled.div`
  position: absolute;
  top: 58px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 1px);
  background: ${(props) => props.theme.colors.neutrals.primary[900]};
  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.divider1};
  display: none;
  z-index: 900;
  width: 100%;
  max-width: 1440px;
  div {
    max-width: 1420px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 8px 24px;
    div {
      margin: 0px;
      width: 100%;
      max-width: 310px;
      padding: 0;
      ul {
        padding: 0 4px;
        width: 100%;
      }
      li {
        display: block;
        text-transform: capitalize;
        font-size: ${(props) => props.theme.fontSizes[5].size};
        font-weight: ${(props) => props.theme.fontWeights.normal};
        padding: 12px 0;
        border-bottom: 1px solid
          ${(props) => props.theme.colors.neutrals.secondary.gray.divider1};

        &:nth-child(1) {
          span {
            color: ${(props) => props.theme.colors.neutrals.primary.white};
            font-size: ${(props) => props.theme.fontSizes[1].size};
            line-height: ${(props) => props.theme.fontSizes[1].lineHeight};
            font-weight: ${(props) => props.theme.fontWeights.normal};
            text-transform: none;
          }
        }
        &:last-child {
          border-bottom: 0;
        }

        a:hover {
          color: ${(props) => props.theme.colors.green[300].accent};
        }
      }
    }
  }
`;

export const FullSearchBoxWrapper = styled.ul`
  width: 100%;
  li {
    width: 100%;
    justify-content: center;
  }
`;
export const CartCounter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0px 6px 0px;
  gap: 8px;
  height: 17px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  border-radius: 100px;
  font-size: ${(props) => props.theme.fontSizes[2].size};
  line-height: ${(props) => props.theme.fontSizes[2].lineHeight};
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

export const AvatarDropdownMenu = styled.div`
  position: absolute;
  display: none;
  top: 59px;
  z-index: 100;
  width: 100%;
  max-width: 210px;

  background: ${(props) => props.theme.colors.neutrals.primary[900]};
  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  border-radius: 6px;
  transform: translate(-105px, 1px);
  div {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    li {
      display: flex;
      line-height: 20px;
      height: 20px;
      padding: 0px 16px;
      margin: 8px;
      text-wrap: nowrap;
    }
    li:hover {
      color: ${(props) => props.theme.colors.green[300].accent};
    }
    li:last-child {
      margin-bottom: 10px;
    }
    li:last-child {
      margin-bottom: 10px;
    }
  }
`;

export const AvatarModalMenu = styled.div`
  display: flex;
  cursor: pointer;
  z-index: 100;
  width: 100%;
  height: 100%;
  ul {
    width: 100%;
  }
  a {
    width: 100%;
  }
  div {
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    li {
      display: flex;
      line-height: 38px;
      height: 38px;
    }
    li:hover {
      color: ${(props) => props.theme.colors.green[300].accent};
    }
  }
`;

export const CartDetailsWrapper = styled.div`
  position: absolute;
  display: none;
  top: 37px;
  right: -16px;
  z-index: 100;
`;

export const AccountCartMenuItem = styled.div`
  position: relative;

  &:hover {
    ${CartDetailsWrapper} {
      display: flex;
    }
  }
`;

export const AccountCartItem = styled.div`
  position: relative;
  z-index: 402;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.0025em;
  color: ${(props) => props.theme.colors.neutrals.primary.white};

  a {
    display: flex;
    flex-direction: row;
    gap: 4px;
    cursor: pointer;
    padding: 8px 8px;
    width: 100%;
    background: ${(props) => props.theme.colors.neutrals.primary[900]};

    div.name {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      svg {
        margin-top: 2px;
        margin-right: 4px;
        min-width: 16px;
      }

      span.title {
        overflow: hidden;
      }

      span.count {
        margin-left: 8px;
        margin-right: 8px;
        padding: 2px 16px;
        background: #262626;
        border-radius: 100px;
      }

      span.total {
        color: #8c8c8c;
        margin-left: auto;
      }
    }

    &:hover {
      color: ${(props) => props.theme.colors.green[300].accent};

      div.name span.total {
        color: ${(props) => props.theme.colors.green[300].accent};
      }
    }
  }
`;

export const AccountCartContent = styled.div`
  max-height: 240px;
  overflow: auto;
  position: relative;
  margin: 0 -16px 0 0;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
`;

export const AccountCartHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  border-bottom: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  padding: 0 16px 16px;
  margin-left: -16px;
  margin-right: -16px;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 0 8px;
  }

  a.close {
    padding: 4px;
    cursor: pointer;
  }
`;

export const AccountCartSelector = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.neutrals.primary[900]};
  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 16px;
`;
