import Button from "@components/Button";
import { DownloadIcon } from "@components/core/icons";
import { Tooltip } from "@components/interaction";
import { ItemLoader } from "@components/loaders";
import { ENCODING_STATUSES } from "@lib/constants";
import { filenamePresets } from "@lib/constants/audio-format";
import { useSessionContext } from "@lib/context/session";
import logger from "@lib/logger";
import {
	getDownloadLink,
	postReDownloadEvent,
	tokenizeZip,
} from "@lib/network/downloads";
import { createFilename, redirectToURL } from "@lib/utils";
import { Track } from "@models/track";
import { getConfig } from "config";
import { TFunction, useTranslation } from "next-i18next";
import { Fragment, useState } from "react";
import {
	DownloadAllButtonElement,
	DownloadAllFullButtonTextSpan,
	ItemLoaderWrapper,
} from "./DownloadButtons.style";

const config = getConfig();

interface Props {
	tracks: Track[];
	disabled?: boolean;
	filenamePattern?: string;
	fullButton?: boolean;
	overallEncodingStatus?: typeof ENCODING_STATUSES[keyof typeof ENCODING_STATUSES];
}

const getTooltipText = (overallEncodingStatus: string | undefined, t: TFunction) => {
	if (overallEncodingStatus === ENCODING_STATUSES.ERROR) {
		return t("Error.ErrorOccurred");
	} else if (overallEncodingStatus === ENCODING_STATUSES.ENCODING) {
		return t("TracksProccessing");
	}

	return "";
};

const TooltipWrapper: React.FC<{ text: string; children: React.ReactNode }> = ({ text, children }) => {
	if (!text) return <Fragment>{children}</Fragment>;

	return (
		<Tooltip text={text} style={{ alignSelf: "end" }}>
			{children}
		</Tooltip>
	);
};

const DownloadAllButton: React.FC<Props> = ({
	tracks,
	disabled = false,
	filenamePattern = filenamePresets[1],
	fullButton = false,
	overallEncodingStatus,
}) => {
	const { t } = useTranslation("translation");
	const [loadingDownload, setLoadingDownload] = useState(false);
	const { getAccessToken } = useSessionContext();
	const accessToken = getAccessToken();

	const isEncodingComplete = overallEncodingStatus ? overallEncodingStatus === ENCODING_STATUSES.COMPLETE : true;
	const tooltipText = getTooltipText(overallEncodingStatus, t);

	const handleDownloadAll: () => void = async () => {
		setLoadingDownload(true);

		try {
			const reDonwloadEventPromises = tracks.map((track) =>
				postReDownloadEvent({ track_ids: track.id }, accessToken),
			);

			await Promise.all(reDonwloadEventPromises);

			const getDownloadLinksPromises = tracks.map((track) =>
				getDownloadLink(
					{ order_item_download_id: track.order_item_download_id || 0 },
					accessToken,
				),
			);

			const downloadItems = (await Promise.all(getDownloadLinksPromises)).map(
				(response) => {
					const data = response.data;
					const track = tracks.find(
						(track) => String(track.id) === data.track_id,
					);
					if (!track) throw new Error("ZIP service error");
					const filename = createFilename(
						track,
						data.extension,
						filenamePattern,
					);
					return { url: data.download_url, filename };
				},
			);

			const { token } = (
				await tokenizeZip({ data: downloadItems }, accessToken)
			).data;

			const downloadUrl = `${config.ZIPPER_URL}/v1/download?token=${token}`;
			redirectToURL(downloadUrl);
		} catch (err) {
			logger.error(err, "Download all error");
		} finally {
			setLoadingDownload(false);
		}
	};

	if (fullButton) {
		return (
			<TooltipWrapper text={tooltipText}>
				<Button
					type="primary"
					disabled={disabled || !isEncodingComplete}
					onClick={handleDownloadAll}
					style={{
						alignSelf: "flex-end",
					}}
				>
					<DownloadAllFullButtonTextSpan>
						{
							loadingDownload ?
									(
										<ItemLoaderWrapper>
											<ItemLoader />
										</ItemLoaderWrapper>
									) :
									(
										<>
											<DownloadIcon /> {t("Actions.DownloadAll")}
										</>
									)
						}
					</DownloadAllFullButtonTextSpan>
				</Button>
			</TooltipWrapper>
		);
	}

	return (
		<TooltipWrapper text={tooltipText}>
			<DownloadAllButtonElement onClick={handleDownloadAll} disabled={disabled}>
				{loadingDownload && (
					<ItemLoaderWrapper>
						<ItemLoader />
					</ItemLoaderWrapper>
				)}
				{!loadingDownload && (
					<DownloadIcon className={`${disabled && "disabled"}`} />
				)}
			</DownloadAllButtonElement>
		</TooltipWrapper>
	);
};

export default DownloadAllButton;
