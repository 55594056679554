import styled from "styled-components";

export const FooterContainer = styled.div`
  background-color: ${(props) => props.theme.colors.neutrals.primary[600]};
  display: flex;
  min-height: 397px;
  row-gap: 30px;
  flex-wrap: wrap;
  padding: 49px 5% 75px 5%;
  justify-content: flex-start;
  @media ${(props) => props.theme.device.lg} {
    justify-content: center;
    padding: 49px 12% 75px 12%;
  }
`;

export const BrandColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media ${(props) => props.theme.device.md} {
    width: 25%;
    max-width: 511px;
  }
`;

export const SocialLinksContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding-right: 10px;
  height: 33.33%;
`;

export const LinksColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 32px;
  @media ${(props) => props.theme.device.md} {
    width: 75%;
    max-width: 1024px;
  }
`;
export const LinksGroup = styled.div`
  width: 100%;
  @media ${(props) => props.theme.device.sm} {
    width: 33.33%;
  }
`;
export const GroupTitle = styled.span`
  color: ${(props) => props.theme.colors.neutrals.primary.white};
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 16px;
  display: block;
  @media ${(props) => props.theme.device.sm} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const LinksList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  & .listItemLink:hover {
    color: rgba(255, 255, 255, 0.9);
  }
  @media ${(props) => props.theme.device.sm} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const LogoWrapper = styled.div`
  min-width: 168px;
  min-height: 52px;
`;

export const Copyright = styled.small`
  display: block;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
`;
