import React, { useEffect, useState } from "react";

import { PLAYED_SESSION_STORAGE_KEY, QUEUE_LOCAL_STORAGE_KEY } from "@lib/constants/storage-keys";
import usePrevious from "@lib/hooks/usePrevious";
import { Track } from "@models/track";
import { pushProductPlayEvent, pushProductQueueEvent } from "@utils/dataLayer";
import { createActions } from "./actions";
import { playerReducer } from "./reducer";
import { PlayerActions, PlayerState } from "./types";

const initialState: PlayerState = {
	queue: [],
	virtualQueue: [],
	currentTrack: undefined,
	shouldPlay: false,
	previouslyPlayedTracks: [],
};

const PlayerStateContext = React.createContext<PlayerState>(initialState);

export const usePlayerState = (): PlayerState => {
	const ctx = React.useContext(PlayerStateContext);
	if (!ctx) {
		throw new Error("usePlayer must be used within PlayerProvider");
	}
	return ctx;
};

const PlayerActionsContext = React.createContext<PlayerActions | null>(null);

export const usePlayerActions = (): PlayerActions => {
	const ctx = React.useContext(PlayerActionsContext);
	if (!ctx) {
		throw new Error(
			"usePlayerActions must be used within PlayerActionsContext",
		);
	}
	return ctx;
};

export type PlayerProviderProps = {
	children: React.ReactNode;
};

const storePlayedTrackInSessionStorage = (trackId: number | string): void => {
	// ? Get previously played tracks from session storage
	const playedTracks = JSON.parse(
		sessionStorage.getItem(PLAYED_SESSION_STORAGE_KEY) || "[]",
	);

	// ? Add the previously played track to the list of played tracks
	sessionStorage.setItem(
		PLAYED_SESSION_STORAGE_KEY,
		JSON.stringify([...playedTracks, trackId]),
	);
};

const storeQueueInLocalStorage = (queue: Track[]) => {
	// Omit `cart_item_data`, if it exists, to avoid circular dependencies.
	const filteredQueuedTracks = queue?.map((track) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { cart_item_data, ...rest } = track;
		return rest;
	});

	if (queue.length) {
		localStorage.setItem(QUEUE_LOCAL_STORAGE_KEY, JSON.stringify(filteredQueuedTracks));
	}
};

const getQueueFromLocalStorage = (): Track[] => {
	const queue = localStorage.getItem(QUEUE_LOCAL_STORAGE_KEY);
	if (queue) {
		return JSON.parse(queue);
	}
	return [];
};

export const PlayerProvider: React.FC<PlayerProviderProps> = ({
	children,
}: PlayerProviderProps): React.ReactElement => {
	const [state, dispatch] = React.useReducer(playerReducer, initialState);
	const [hasLoadedQueueFromStorage, setHasLoadedQueueFromStorage] = useState<boolean>(false);
	const [lastPlayedTrackEventId, setLastPlayedTrackEventId] = useState<string | undefined>();
	const playerActions = createActions(dispatch);
	const previousTrack = usePrevious(state.currentTrack);
	const previousQueue = usePrevious(state.queue);

	// ? Keep track of played tracks in session storage
	useEffect(() => {
		if (
			previousTrack?.id &&
			previousTrack?.id !== state.currentTrack?.id &&
			!state.previouslyPlayedTracks.includes(previousTrack?.id)
		) {
			storePlayedTrackInSessionStorage(previousTrack?.id);

			playerActions.setPlayedHistory([
				...state.previouslyPlayedTracks,
				previousTrack?.id,
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [previousTrack, state.currentTrack]);

	// ? Load previously played tracks from session storage
	useEffect(() => {
		const playedTrackIds = JSON.parse(
			sessionStorage.getItem(PLAYED_SESSION_STORAGE_KEY) || "[]",
		);

		playerActions.setPlayedHistory(playedTrackIds);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ? Store queue in local storage for persistence
	useEffect(() => {
		storeQueueInLocalStorage(state.queue);
	}, [state.queue]);

	// ? Load queue from local storage
	useEffect(() => {
		const queue = getQueueFromLocalStorage();
		playerActions.setQueue(queue);

		if (queue.length) {
			setHasLoadedQueueFromStorage(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ? Record event when a track is played
	useEffect(() => {
		if (state.currentTrack && state.currentTrack?.unique_player_id !== lastPlayedTrackEventId && state.shouldPlay) {
			pushProductPlayEvent({
				name: state.currentTrack.name,
				label: state.currentTrack?.release?.label?.name,
				artists: state.currentTrack?.artists?.map((artist) => artist.name).join(", ") || null,
				remixers: state.currentTrack?.remixers?.map((remixer) => remixer.name).join(", ") || null,
				genres: state.currentTrack?.genre?.name,
				subGenres: state.currentTrack?.sub_genre?.name || null,
				preOrder: state.currentTrack?.pre_order || null,
			});
			setLastPlayedTrackEventId(state.currentTrack?.unique_player_id);
		}
	}, [state.currentTrack, state.shouldPlay, lastPlayedTrackEventId, setLastPlayedTrackEventId]);

	// ? Record event when track(s) are added to the queue
	useEffect(() => {
		if (previousQueue && state.queue.length > previousQueue.length) {
			if (hasLoadedQueueFromStorage) {
				setHasLoadedQueueFromStorage(false);
				return;
			}

			const newTracks = state.queue.filter((track) => !previousQueue.includes(track));
			const products = newTracks.map((track) => ({
				name: track.name,
				label: track?.release?.label?.name,
				artists: track?.artists?.map((artist) => artist.name).join(", ") || null,
				remixers: track?.remixers?.map((remixer) => remixer.name).join(", ") || null,
				genres: track?.genre?.name,
				subGenres: track?.sub_genre?.name || null,
				preOrder: track?.pre_order || null,
			}));

			pushProductQueueEvent({ products });
		}
	}, [previousQueue, state.queue, hasLoadedQueueFromStorage, setHasLoadedQueueFromStorage]);

	return (
		<PlayerStateContext.Provider value={state}>
			<PlayerActionsContext.Provider value={playerActions}>
				{children}
			</PlayerActionsContext.Provider>
		</PlayerStateContext.Provider>
	);
};
