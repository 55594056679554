import axios from "axios";
import { getConfig } from "config";
import { AUTH_TOKEN_URL, CONTENT_TYPES } from "./constants/urls";
const {
	API4_CLIENT_ID,
	API4_CLIENT_SECRET,
	API4_INTERNAL_BASE_URL,
} = getConfig();

export const payload: Record<string, string> = {
	grant_type: "client_credentials",
	client_id: API4_CLIENT_ID,
	client_secret: API4_CLIENT_SECRET,
};

const TEN_MINUTES = 600;

export class GlobalRef {
	private sym: symbol;

	constructor(uniqueName: string) {
		this.sym = Symbol.for(uniqueName);
	}

	async refreshAccessToken() {
		const res = await axios({
			method: "post",
			url: AUTH_TOKEN_URL,
			baseURL: API4_INTERNAL_BASE_URL,
			data: new URLSearchParams(payload).toString(),
			headers: {
				"Content-Type": CONTENT_TYPES.formUrlEncoded,
			},
		})
			.then((res) => res)
			.catch((error) => {
				console.error(error);
			});
		if (res?.data) {
			res.data.create_date = new Date();
			(global as any)[this.sym] = res.data;
			return res.data.access_token;
		}
	}

	async getAccessToken() {
		if (!(global as any)[this.sym]) {
			return this.refreshAccessToken();
		}

		if ((global as any)[this.sym]) {
			const tokenAgeSeconds =
        (new Date().getTime() -
        (global as any)[this.sym].create_date.getTime()) /
        1000;

			if (
				tokenAgeSeconds >
				(global as any)[this.sym].expires_in - TEN_MINUTES
			) {
				return this.refreshAccessToken();
			}
			return (global as any)[this.sym].access_token;
		}
	}

	async exportSession() {
		const session: AnonSession = (global as any)[this.sym];

		if (!session) {
			return await this.refreshAccessToken();
		}

		return {
			...session,
			create_date: session.create_date.toString(),
		};
	}

	importSession(token: AnonSession) {
		(global as any)[this.sym] = {
			...token,
			create_date: new Date(token.create_date),
		};
	}

	accessToken() {
		return (global as any)[this.sym]?.access_token;
	}
}
