import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import Image from "next/image";
import Link from "next/link";
import { setCookie } from "nookies";
import { FunctionComponent } from "react";
import {
	BrandColumnContainer,
	Copyright,
	FooterContainer,
	GroupTitle,
	LinksColumnContainer,
	LinksGroup,
	LinksList,
	ListItem,
	LogoWrapper,
	SocialLinksContainer,
} from "./Footer.style";

import {
	AFEM_LOGO,
	BEATPORT_LOGO,
	FOOTER_LINKS,
	SOCIAL_LINKS,
} from "@lib/constants";

import { FooterLink, FooterLinkCategory } from "@models/constants";
import { Trans } from "next-i18next";
import { useRouter } from "next/router";

const Footer: FunctionComponent = () => {
	const router = useRouter();
	const setLanguage = (code: string) => {
		// Set language cookie
		setCookie(null, "NEXT_LOCALE", code, { maxAge: 30 * 24 * 60 * 60, path: "/" });
	};

	return (
		<FooterContainer id="footer">
			<BrandColumnContainer>
				<LogoWrapper>
					<Image
						src={BEATPORT_LOGO.image.path}
						alt={BEATPORT_LOGO.title}
						width={BEATPORT_LOGO.image.width}
						height={BEATPORT_LOGO.image.height}
					/>
					<Copyright>{BEATPORT_LOGO.copyright}</Copyright>
				</LogoWrapper>
				<SocialLinksContainer>
					{SOCIAL_LINKS.map(({ title, href, icon, cypressTag }) => (
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={href}
							key={title}
							data-testid={cypressTag}
						>
							<SpriteIcon
								id={icon.path}
								title={title}
								width={icon.width.toString()}
								height={icon.height.toString()}
							/>
						</a>
					))}
				</SocialLinksContainer>
				<div>
					<Link href={AFEM_LOGO.href} prefetch={false} data-testid={AFEM_LOGO.cypressTag}>
						<Image
							src={AFEM_LOGO.image.path}
							alt={AFEM_LOGO.title}
							width={AFEM_LOGO.image.width}
							height={AFEM_LOGO.image.height}
							style={{
								maxWidth: "100%",
								height: "auto",
							}}
						/>
					</Link>
				</div>
			</BrandColumnContainer>
			<LinksColumnContainer>
				{FOOTER_LINKS.map(({ label, links }: FooterLinkCategory | any) => (
					<LinksGroup key={label}>
						<GroupTitle><Trans>{label}</Trans></GroupTitle>
						<LinksList>
							{
								label === "Languages" ?
									links.map(({ label, code, cypressTag }: FooterLink) => (
										<ListItem key={label}>
											<Link className="listItemLink" href={router.asPath} onClick={() => setLanguage(code ?? "en")} locale={code} data-testid={cypressTag}>
												<Trans>{label}</Trans>
											</Link>
										</ListItem>
									)) :
									links.map(({ label, href, cypressTag }: FooterLink) => (
										<ListItem key={label}>
											<a
												className="listItemLink"
												target="_blank"
												rel="noopener noreferrer"
												href={href}
												data-testid={cypressTag}
											>
												<Trans>{label}</Trans>
											</a>
										</ListItem>
									))
							}
						</LinksList>
					</LinksGroup>
				))}
			</LinksColumnContainer>
		</FooterContainer>
	);
};

export default Footer;
