import styled, { css } from "styled-components";

export const TooltipContent = css`
  position: absolute;
  content: "";
`;

export const TooltipContainer = styled.div`
  display: none;
  flex-direction: center;
  justify-content: center;

  background: #000;
  padding: 4px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  border-radius: 8px;
  transition: background 0.2s;

  background: ${(props) => props.theme.colors.neutrals.primary[900]};
  color: ${(props) => props.theme.colors.neutrals.primary.white};
  text-align: center;
  padding: 4px 4px;
  width: 86px;
  position: absolute;
  z-index: 2;
  filter: none;

  left: 50%;
  margin-left: -43px;

  &.top {
    bottom: 155%;
    &:after {
      ${TooltipContent}
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border: 5px solid transparent;
      border-top-color: ${(props) => props.color || props.theme.colors.neutrals.primary[900]};
    }
  }

  &.top-left {
    bottom: 155%;
    left: -60%;
    &:after {
      ${TooltipContent}
      top: 100%;
      margin-right: -50px;
      border: 5px solid transparent;
      border-top-color: ${(props) => props.color || props.theme.colors.neutrals.primary[900]};
    }
  }

  &.top-right {
    bottom: 155%;
    right: -60%;
    &:after {
      ${TooltipContent}
      top: 100%;
      margin-left: -50px;
      border: 5px solid transparent;
      border-top-color: ${(props) => props.color || props.theme.colors.neutrals.primary[900]};
    }
  }

  &.bottom {
    top: 115%;
    &:before {
      ${TooltipContent}
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border: 5px solid transparent;
      border-bottom-color: ${(props) => props.color || props.theme.colors.neutrals.primary[900]};
    }
  }

  &.left {
    right: calc(100% + 0.4rem);
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: unset;
    margin-left: unset;
    height: 1.7rem;
    padding: 0.3rem 0.6rem;
    width: 600px;
    max-width: max-content;
    &:after {
      ${TooltipContent}
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border: 5px solid transparent;
      border-left-color: ${(props) =>
		props.color || props.theme.colors.neutrals.primary[900]};
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  &:hover ${TooltipContainer} {
    display: flex;
  }
`;
