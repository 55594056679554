import Button from "@components/Button";
import { Box } from "@components/core/Box";
import { TextInput } from "@components/core/Form";
import {
	CalendarWrapper,
	FilterForm,
	FilterFormBody,
	FilterFormFooter,
	ReleaseQuickFilter,
} from "@components/filters/FacetFilter/FacetFilter.style";
import { ACTIVE_FILTER } from "@lib/constants/filters";
import { format } from "date-fns";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const DATE = {
	MIN: "date_min",
	MAX: "date_max",
};

interface DateFilterProps {
	min: Date | undefined;
	max: Date | undefined;
	visible: string;
	inputLabel: string;
	inputName: string;
}

interface Props {
	dateFilter: DateFilterProps;
	setDateFilter: (newDateValue: DateFilterProps) => void;
	applyDateFilter: (newDateValue: DateFilterProps) => void;
	resetGroup: () => void;
}
const DateFilter = (props: Props) => {
	const { t } = useTranslation("translation");
	const { dateFilter, setDateFilter, applyDateFilter, resetGroup } = props;
	const [lastModifiedInputType, setLastModifiedInputType] = useState(DATE.MIN);
	const visibleDateIsMin =
		dateFilter.visible === DATE.MIN || dateFilter.visible === "";

	const setDateQuickFilter = (days: number) => {
		const endDate = new Date(
			new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0, 0),
		);

		let startDate = new Date(new Date().setHours(0, 0, 0, 0));
		startDate = new Date(startDate.setDate(startDate.getDate() + days));

		setDateFilter({
			...dateFilter,
			min: startDate,
			max: endDate,
		});
	};

	const DatePickerFooter = () => {
		const valueSelectedText = () => {
			const textValue = lastModifiedInputType === DATE.MIN ? t("Actions.Start") : t("Actions.End");
			const dateValue =
				(lastModifiedInputType === DATE.MIN ?
					dateFilter.min :
					dateFilter.max) || new Date();
			return (
				<span>
					<p>
						{`You selected ${format(
							dateValue,
							"PP",
						)} as ${textValue} date`}
					</p>
				</span>
			);
		};

		const pleaseSelectValueText = () => {
			const textValue = visibleDateIsMin ? t("Actions.Start") : t("Actions.End");
			return (
				<span>
					<p>{t("Dates.PleasePick", { textValue: textValue })}</p>
				</span>
			);
		};

		if (
			(visibleDateIsMin && !dateFilter.min) ||
			(!visibleDateIsMin && !dateFilter.max)
		) {
			return pleaseSelectValueText();
		}

		return valueSelectedText();
	};

	return (
		<FilterForm className="group-release-date">
			<FilterFormBody className="group-release-date">
				<Box p="0" direction="row" justifyContent="space-between">
					<ReleaseQuickFilter>
						<div onClick={() => setDateQuickFilter(0)}>{t("Dates.Today")}</div>
						<div onClick={() => setDateQuickFilter(-1)}>{t("Dates.Yesterday")}</div>
						<div onClick={() => setDateQuickFilter(-7)}>{t("Dates.LastDays", { number: "7" })}</div>
						<div onClick={() => setDateQuickFilter(-30)}>{t("Dates.LastDays", { number: "30" })}</div>
					</ReleaseQuickFilter>
				</Box>
				<Box p="0" direction="row" justifyContent="space-between">
					<Box p="4">
						<TextInput
							id={DATE.MIN}
							name={DATE.MIN}
							type="text"
							placeholder={t("Dates.StartDate")}
							value={dateFilter.min ? format(dateFilter.min, "PP") : ""}
							readOnly
							onClick={() => {
								setDateFilter({
									...dateFilter,
									visible: DATE.MIN,
								});
							}}
						/>
					</Box>
					<Box p="4">
						<TextInput
							id={DATE.MAX}
							name={DATE.MAX}
							type="text"
							placeholder={t("Dates.EndDate")}
							value={dateFilter.max ? format(dateFilter.max, "PP") : ""}
							readOnly
							onClick={() => {
								setDateFilter({
									...dateFilter,
									visible: DATE.MAX,
								});
							}}
						/>
					</Box>
				</Box>

				<CalendarWrapper
					className={dateFilter.visible !== "" ? ACTIVE_FILTER : undefined}
				>
					<DayPicker
						mode="range"
						selected={{
							from: dateFilter.min,
							to: dateFilter.max,
						}}
						fromYear={1990}
						toYear={new Date().getFullYear() + 1}
						captionLayout="dropdown"
						onSelect={(day) => {
							const newDateFilter = { ...dateFilter };
							newDateFilter.min = day?.from;
							newDateFilter.max = day?.to;
							if (!dateFilter.max) {
								newDateFilter.visible = DATE.MAX;
							}

							if (newDateFilter.min !== dateFilter.min) {
								setLastModifiedInputType(DATE.MIN);
							} else if (newDateFilter.max !== dateFilter.max) {
								setLastModifiedInputType(DATE.MAX);
							}

							setDateFilter(newDateFilter);
						}}
						footer={<DatePickerFooter />}
					/>
				</CalendarWrapper>
			</FilterFormBody>
			<FilterFormFooter>
				<Button
					data-testid="date_filter-button_reset"
					type="link"
					onClick={() => {
						resetGroup();
						setDateFilter({
							...dateFilter,
							min: undefined,
							max: undefined,
							visible: "",
						});
					}}
				>
					{t("Reset")}
				</Button>
				<Button
					data-testid="date_filter-button_apply"
					type="primary"
					onClick={() => {
						if (dateFilter.min || dateFilter.max) {
							applyDateFilter(dateFilter);
						}
					}}
				>
					{t("Apply")}
				</Button>
			</FilterFormFooter>
		</FilterForm>
	);
};

export default DateFilter;
