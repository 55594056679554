import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { BEATPORT_FEEDBACK_SURVEY } from "@lib/constants/urls";
import { Account } from "@models/account";
import { IntrospectUser } from "@models/auth";
import { useTranslation } from "next-i18next";
import { ButtonStyled } from "./FeedbackButton.style";

interface FeedbackButtonProps {
	userIntrospect: IntrospectUser | null;
	userAccount: Account | null;
}

const FeedbackButton = ({ userIntrospect, userAccount }: FeedbackButtonProps) => {
	const { t } = useTranslation("translation");
	const isLoggedIn = !!userIntrospect?.user_id;

	const onClick = () => {
		if (isLoggedIn && userIntrospect) {
			const feedbackUrl = new URL(BEATPORT_FEEDBACK_SURVEY + "&authentication=yes");
			feedbackUrl.searchParams.set("username", userIntrospect?.username);
			feedbackUrl.searchParams.set("first_name", userIntrospect?.first_name || "");
			feedbackUrl.searchParams.set("last_name", userIntrospect?.last_name || "");
			feedbackUrl.searchParams.set("email", userAccount?.email || "");
			feedbackUrl.searchParams.set("url", window.location.href);

			window.open(feedbackUrl, "_blank");
			return;
		}

		const feedbackUrl = new URL(BEATPORT_FEEDBACK_SURVEY + "&authentication=no");
		feedbackUrl.searchParams.set("url", window.location.href);
		window.open(feedbackUrl, "_blank");
	};

	return (
		<ButtonStyled onClick={onClick}>
			<SpriteIcon id="comment" />
			<span>{t("Feedback")}</span>
		</ButtonStyled>
	);
};

export default FeedbackButton;
