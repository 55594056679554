import {
	AUDIO_FORMAT_IDS,
	DEFAULT_AUDIO_FORMAT,
	PURCHASE_TYPE_IDS,
} from "@lib/constants";
import { Audioformat } from "@models/audio-format";
import { Release } from "@models/release";
import { Track } from "@models/track";

export const getFilenameDate = () => {
	const today = new Date();
	const dd = String(today.getDate()).padStart(2, "0");
	const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
	const yyyy = String(today.getFullYear());
	return { dd, mm, yyyy };
};
export type FilenameKeywordsType = {
	track_name: {
		display: string;
		exampleText: string;
		param: string;
	};
	track_id: {
		display: string;
		exampleText: string;
		param: string;
	};
	mix_name: {
		display: string;
		exampleText: string;
		param: string;
	};
	genre: {
		display: string;
		exampleText: string;
		param: string;
	};
	key: {
		display: string;
		exampleText: string;
		param: string;
	};
	bpm: {
		display: string;
		exampleText: string;
		param: string;
	};

	spacerA: { spacer: boolean; exampleText: string };

	artists: {
		display: string;
		exampleText: string;
		param: string;
	};
	remixers: {
		display: string;
		exampleText: string;
		param: string;
	};
	label: {
		display: string;
		exampleText: string;
		param: string;
	};

	spacerB: { spacer: boolean; exampleText: string };

	release_day: {
		display: string;
		exampleText: string;
		param: string;
	};
	release_month: {
		display: string;
		exampleText: string;
		param: string;
	};
	release_year: {
		display: string;
		exampleText: string;
		param: string;
	};

	spacerC: { spacer: boolean; exampleText: string };

	purchase_day: {
		display: string;
		exampleText: string;
		param: string;
	};
	purchase_month: {
		display: string;
		exampleText: string;
		param: string;
	};
	purchase_year: {
		display: string;
		exampleText: string;
		param: string;
	};

	spacerD: { spacer: boolean; exampleText: string };

	download_day: {
		display: string;
		exampleText: string;
		param: string;
	};
	download_month: {
		display: string;
		exampleText: string;
		param: string;
	};
	download_year: {
		display: string;
		exampleText: string;
		param: string;
	};
};
export const filenameKeywords: FilenameKeywordsType = {
	track_name: {
		display: "TrackName",
		exampleText: "Some Track",
		param: "{track_name}",
	},
	track_id: {
		display: "TrackID",
		exampleText: "123456",
		param: "{track_id}",
	},
	mix_name: {
		display: "MixName",
		exampleText: "Original Mix",
		param: "{mix_name}",
	},
	genre: {
		display: "Genre",
		exampleText: "Deep House",
		param: "{genre}",
	},
	key: {
		display: "MusicalKey",
		exampleText: "F#min",
		param: "{key}",
	},
	bpm: {
		display: "BPM",
		exampleText: "128",
		param: "{bpm}",
	},

	spacerA: { spacer: true, exampleText: "" },

	artists: {
		display: "Artists",
		exampleText: "Someone",
		param: "{artists}",
	},
	remixers: {
		display: "Remixers",
		exampleText: "Some Remixer",
		param: "{remixers}",
	},
	label: {
		display: "LabelName",
		exampleText: "Great Label",
		param: "{label}",
	},

	spacerB: { spacer: true, exampleText: "" },

	release_day: {
		display: "ReleaseDay",
		exampleText: "01",
		param: "{release_day}",
	},
	release_month: {
		display: "ReleaseMonth",
		exampleText: "07",
		param: "{release_month}",
	},
	release_year: {
		display: "ReleaseYear",
		exampleText: "2004",
		param: "{release_year}",
	},

	spacerC: { spacer: true, exampleText: "" },

	purchase_day: {
		display: "PurchaseDay",
		exampleText: "01",
		param: "{purchase_day}",
	},
	purchase_month: {
		display: "PurchaseMonth",
		exampleText: "05",
		param: "{purchase_month}",
	},
	purchase_year: {
		display: "PurchaseYear",
		exampleText: "2020",
		param: "{purchase_month}",
	},

	spacerD: { spacer: true, exampleText: "" },

	download_day: {
		display: "DownloadDay",
		exampleText: getFilenameDate().dd,
		param: "{download_day}",
	},
	download_month: {
		display: "DownloadMonth",
		exampleText: getFilenameDate().mm,
		param: "{download_month}",
	},
	download_year: {
		display: "DownloadYear",
		exampleText: getFilenameDate().yyyy,
		param: "{download_year}",
	},
};

export const filenamePresets = [
	"{track_id}_{track_name}_({mix_name})",
	"{artists} - {track_name} ({mix_name})",
];

export const renderAudioFormatName = (
	audioFormats: Audioformat[],
	audioFormatId?: number,
	purchaseTypeId?: number,
	item?: Track | Release,
) => {
	const audioFormat = audioFormats.filter(
		(audioFormatItem) => audioFormatItem.id === audioFormatId,
	);

	if (!audioFormat) return "";
	if (audioFormat.length === 0) return "";

	if (purchaseTypeId === PURCHASE_TYPE_IDS.upgrade) {
		const audioFormatPurchase = AUDIO_FORMAT_IDS.filter(
			(audioFormatItem) => audioFormatItem.id === audioFormatId,
		);

		const upgradeType =
			audioFormatPurchase.length > 0 ?
				audioFormatPurchase[0].name :
				DEFAULT_AUDIO_FORMAT.name;

		return `${upgradeType} Upgrade`;
	}
	const purchaseFee = audioFormat[0].fees["item_purchase"];

	let price = "";

	if (purchaseFee) {
		price = item && "track_count" in item ?
			`+ ${purchaseFee.symbol}${(purchaseFee.value * item.track_count).toFixed(2)}` :
			`+ ${purchaseFee.display}`;
	}

	return `${audioFormat[0].name} ${price}`;
};
