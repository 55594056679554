import { API_BASES, ONE_DAY_SECONDS } from "@lib/constants";
import { PurchaseItem } from "@models/Cart";
import { Chart } from "@models/Chart";
import { Account } from "@models/account";
import { LocationParams } from "@models/facets";
import { MY_CART_BASE_KEY } from "../context/cart";
import {
	deleteRequest,
	getRequest,
	patchRequest,
	postRequest,
	putRequest,
} from "./request";

const urlPayload = (params: any) => new URLSearchParams(params);

const MY_GENRE_REQUEST_PARAMS = { page: 1, per_page: 100 };

export const MY_URLS = {
	MY_CARTS: "/my/carts/",
	MY_ACCOUNT: "/my/account/",
};

export const getMyGenresRequest = async (
	params: {
		page?: number;
		per_page?: number;
	},
	accessToken: string | undefined,
	withErrorHandling?: boolean,
) => {
	const url = `my/genres/?${urlPayload(params)}`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken },
		withErrorHandling,
	);
};

export const getMyGenresQuery = (accessToken: string) => ({
	queryKey: [`my-genres-${urlPayload(MY_GENRE_REQUEST_PARAMS)}`],
	queryFn: async () => {
		const { data } = await getMyGenresRequest(
			MY_GENRE_REQUEST_PARAMS,
			accessToken,
			false,
		);
		return data;
	},
});

export const postMyGenreRequest = async (
	params: { id: number },
	accessToken: string,
) => {
	const url = "/my/genres/";
	return postRequest({
		url,
		apiBase: API_BASES.api4,
		accessToken,
		data: params,
	});
};

export const deleteMyGenreRequest = async (
	params: { id: number },
	accessToken: string,
) => {
	const url = `/my/genres/${params.id}/`;
	return deleteRequest({ url, apiBase: API_BASES.api4, accessToken });
};

export const postMyChartsRequest = async (
	params: { name: string },
	accessToken: string,
) => {
	const url = "/my/charts/";
	return postRequest({
		url,
		apiBase: API_BASES.api4,
		data: params,
		accessToken,
	});
};

export const deleteMyChartRequest = async (
	params: { id: number },
	accessToken: string,
) => {
	if (!Number.isInteger(params.id) || params.id <= 0)
		throw new Error("Chart id must be a positive number");
	const url = `/my/charts/${params.id}/`;
	return deleteRequest({
		url,
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const putMyChartRequest = async (
	params: { chartName: string; chartId: number; chartDescription: string },
	accessToken: string,
) => {
	const url = `/my/charts/${params.chartId}/`;
	return putRequest({
		url,
		apiBase: API_BASES.api4,
		data: { name: params.chartName, description: params.chartDescription },
		accessToken,
	});
};

export const getMyChartById = async (
	params: { id: string },
	accessToken: string | undefined,
	withErrorHandling?: boolean,
) => {
	const url = `/my/charts/${params.id}`;
	return getRequest(
		{
			url,
			apiBase: API_BASES.api4,
			accessToken,
		},
		withErrorHandling,
	);
};

export const getMyChartByIdQuery = (id: string, accessToken: string | undefined) => ({
	queryKey: [`my-chart-${id}`],
	queryFn: async () => {
		const { data } = await getMyChartById({ id }, accessToken, false);
		return data as Chart;
	},
});

export const patchMyChartRequest = async (
	id: number,
	params: { is_published: boolean },
	accessToken: string,
) => {
	const url = `/my/charts/${id}/`;
	return patchRequest({
		url,
		data: params,
		apiBase: API_BASES.api4,
		accessToken,
	});
};

export const getMySubscriptionsRequest = async ({
	accessToken,
	location,
	withErrorHandling,
}: {
	accessToken: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = "/my/subscriptions/";
	return getRequest(
		{
			url: url,
			apiBase: API_BASES.api4,
			accessToken: accessToken,
			location,
		},
		withErrorHandling,
	);
};

export const getMySubscriptionsQuery = ({
	accessToken,
	location,
	personId = "anon",
}: {
	accessToken: string;
	location?: LocationParams;
	personId?: string | number | undefined;
}) => ({
	queryKey: [`my-subscriptions-${personId}`],
	queryFn: async () => {
		const { data } = await getMySubscriptionsRequest({
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
	staleTime: ONE_DAY_SECONDS,
	cacheTime: ONE_DAY_SECONDS,
});

export const postMySubscriptionRequest = async (
	params: Record<string, string>,
	accessToken: string,
) => {
	const url = "/my/subscriptions/";
	return postRequest({
		url: url,
		apiBase: API_BASES.api4,
		data: params,
		accessToken: accessToken,
	});
};

export const patchMySubscriptionChangeRequest = async (
	params: Record<string, string>,
	accessToken: string,
) => {
	const url = "/my/subscriptions/change/";
	return patchRequest({
		url: url,
		apiBase: API_BASES.api4,
		data: params,
		accessToken: accessToken,
	});
};

export const getMyEmailPreferencesRequest = async (
	accessToken: string | undefined,
	withErrorHandling?: boolean,
) => {
	const url = "/my/email-preferences/";
	return getRequest(
		{
			url: url,
			accessToken: accessToken,
			apiBase: API_BASES.api4,
		},
		withErrorHandling,
	);
};

export const getMyEmailPreferencesQuery = (accessToken: string) => {
	return {
		queryKey: ["my-email-preferences"],
		queryFn: async () => {
			const { data } = await getMyEmailPreferencesRequest(accessToken, false);

			// Parse the key for these values to avoid overhead. API returns them uncapitalized,
			// but it receives them capitalized.
			data.Snooze_14 = data.snooze_14;
			data.Snooze_30 = data.snooze_30;
			data.Snooze_60 = data.snooze_60;
			delete data.snooze_14;
			delete data.snooze_30;
			delete data.snooze_60;

			return data;
		},
	};
};

export const patchMyEmailPreferencesRequest = async (
	params: { [x: string]: FormDataEntryValue },
	accessToken: string,
) => {
	const url = "/my/email-preferences/";
	return patchRequest({
		url: url,
		apiBase: API_BASES.api4,
		data: params,
		accessToken: accessToken,
	});
};

export const getMyBeatportRequest = async (
	accessToken: string | undefined,
	withErrorHandling?: boolean,
) => {
	const url = "/my/beatport";
	return getRequest(
		{
			url,
			accessToken,
			apiBase: API_BASES.api4,
		},
		withErrorHandling,
	);
};

export const getMyBeatportQuery = (
	accessToken: string | undefined,
	enabled: boolean = true,
) => ({
	queryKey: ["my-beatport"],
	queryFn: async () => {
		const { data } = await getMyBeatportRequest(accessToken, false);
		return data;
	},
	enabled: enabled,
});

export const postFollowArtistRequest = async (
	artistId: number,
	accessToken: string,
) => {
	const url = "/my/beatport/";
	return postRequest({
		url,
		data: { artist_ids: [artistId] },
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const deleteFollowArtistRequest = async (
	artistId: number,
	accessToken: string,
) => {
	const url = "/my/beatport/";
	return deleteRequest({
		url,
		data: { artist_ids: [artistId] },
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const postFollowLabelRequest = async (
	artistId: number,
	accessToken: string,
) => {
	const url = "/my/beatport/";
	return postRequest({
		url,
		data: { label_ids: [artistId] },
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const deleteFollowLabelRequest = async (
	artistId: number,
	accessToken: string,
) => {
	const url = "/my/beatport/";
	return deleteRequest({
		url,
		data: { label_ids: [artistId] },
		accessToken,
		apiBase: API_BASES.api4,
	});
};

type FollowRequestType = (
	id: number,
	type:
		| "genre"
		| "artist"
		| "label"
		| "charts"
		| "my-beatport"
		| "recommended-tracks",
	accessToken: string
) => Promise<ApiResponse>;

export const postFollowRequest: FollowRequestType = async (
	id,
	type,
	accessToken,
) => {
	if (type === "artist") {
		return postFollowArtistRequest(id, accessToken);
	} else {
		return postFollowLabelRequest(id, accessToken);
	}
};

export const deleteFollowRequest: FollowRequestType = async (
	id,
	type,
	accessToken,
) => {
	if (type === "artist") {
		return deleteFollowArtistRequest(id, accessToken);
	} else {
		return deleteFollowLabelRequest(id, accessToken);
	}
};

export const getMyCartsRequest = async ({
	accessToken,
	location,
	withErrorHandling,
}: {
	accessToken: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = MY_URLS.MY_CARTS;
	return getRequest(
		{ url, accessToken, apiBase: API_BASES.api4, location },
		withErrorHandling,
	);
};

export const getMyCartsQuery = ({
	accessToken,
	enabled = true,
	location,
}: {
	accessToken: string;
	enabled?: boolean;
	location?: LocationParams;
}) => ({
	queryKey: ["my-carts"],
	queryFn: async () => {
		const { data } = await getMyCartsRequest({
			accessToken: accessToken || "",
			location,
			withErrorHandling: false,
		});
		return data;
	},
	enabled: enabled,
});

export const getMyCartRequest = async ({
	cartId,
	accessToken,
	location,
	withErrorHandling,
}: {
	cartId: number;
	accessToken: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `${MY_URLS.MY_CARTS}${cartId}/?items=false`; // !!! This must always be false or it nukes the API
	return getRequest(
		{ url, accessToken, apiBase: API_BASES.api4, location },
		withErrorHandling,
	);
};

export const getMyCartQuery = ({
	cartId,
	accessToken,
	enabled = true,
	location,
}: {
	cartId: number;
	accessToken: string;
	enabled?: boolean;
	location?: LocationParams;
}) => ({
	queryKey: [`${MY_URLS.MY_CARTS}${cartId}/`],
	queryFn: async () => {
		const { data } = await getMyCartRequest({
			cartId,
			accessToken: accessToken || "",
			location,
			withErrorHandling: false,
		});
		return data;
	},
	enabled: enabled,
});

export const getMyCartItems = async (
	id: number,
	itemsType: string,
	params: Record<string, any>,
	accessToken: string | undefined,
	withErrorHandling?: boolean,
) => {
	const url = `${MY_URLS.MY_CARTS}${id}/items/${itemsType}/`;
	return getRequest(
		{
			url,
			params: params,
			accessToken,
			apiBase: API_BASES.api4,
		},
		withErrorHandling,
	);
};

export const getMyCartItemsQuery = (
	id: number,
	itemsType: string = "tracks",
	params: Record<string, any>,
	accessToken: string | undefined,
	enabled: boolean = true,
	isCartPage: boolean = false,
) => {
	return {
		queryKey: [
			MY_CART_BASE_KEY,
			{
				id,
				itemsType,
				page: params.page,
				per_page: params.per_page,
				isCartPage,
			},
		],
		queryFn: async ({ pageParam = 1 }) => {
			const newParams = { ...params, page: pageParam };
			const { data } = await getMyCartItems(
				id,
				itemsType,
				newParams,
				accessToken,
				false,
			);
			return data;
		},
		getNextPageParam: (lastPage: any) => {
			const parts = lastPage?.page?.split("/");

			if (
				!parts ||
				parts.length !== 2 ||
				(parts.length === 2 && parts[0] === parts[1])
			) {
				return undefined;
			}

			const page = Number(parts[0]);

			if (isNaN(page)) {
				return undefined;
			}

			return page + 1;
		},
		enabled: enabled,
	};
};

type Item = {
	item_id: number;
	item_type_id: number;
	audio_format_id: number;
	purchase_type_id: number;
	source_type_id: number;
};

export const postCartItemsRequest = async (
	cartId: number,
	items: Item[],
	accessToken: string | undefined,
): Promise<ApiResponse> => {
	const url = `${MY_URLS.MY_CARTS}${cartId}/items/bulk/`;
	const params = {
		items: items,
	};

	try {
		const result = await postRequest({
			url: url,
			data: params,
			accessToken: accessToken,
			apiBase: API_BASES.api4,
		});
		return result;
	} catch (error) {
		return error as ApiResponse;
	}
};

export const postCartItemRequest = async (
	cartId: number,
	itemId: number,
	itemTypeId: number,
	audioformatId: number,
	purchaseTypeId: number,
	sourceTypeId: number,
	accessToken: string | undefined,
	overridePurchase?: boolean,
): Promise<ApiResponse> => {
	const url = overridePurchase ?
		`${MY_URLS.MY_CARTS}${cartId}/items/?already_purchased_override=true` :
		`${MY_URLS.MY_CARTS}${cartId}/items/`;
	const params = {
		item_id: itemId,
		item_type_id: itemTypeId,
		audio_format_id: audioformatId,
		purchase_type_id: purchaseTypeId,
		source_type_id: sourceTypeId,
	};
	try {
		const result = await postRequest({
			url: url,
			data: params,
			accessToken: accessToken,
			apiBase: API_BASES.api4,
		});
		return result;
	} catch (error) {
		return error as ApiResponse;
	}
};

export const patchCartItemRequest = async (
	cartId: number,
	itemId: number,
	data: Record<string, any>,
	accessToken: string,
) => {
	const url = `${MY_URLS.MY_CARTS}${cartId}/items/${itemId}/`;
	return patchRequest({
		url: url,
		data: data,
		accessToken: accessToken,
		apiBase: API_BASES.api4,
	});
};

export const deleteCartItemRequest = async (
	cartId: number,
	itemId: number,
	accessToken: string,
) => {
	const url = `${MY_URLS.MY_CARTS}${cartId}/items/${itemId}/`;
	return deleteRequest({
		url: url,
		accessToken: accessToken,
		apiBase: API_BASES.api4,
	});
};

export const postCartChartRequest = async (
	cartId: number,
	chartId: number,
	accessToken: string,
) => {
	const url = `/api/carts/${cartId}/charts`;
	return postRequest({
		url: url,
		data: {
			chartId: chartId,
		},
		accessToken: accessToken,
	});
};

export const postCartPlaylistRequest = async (
	cartId: number,
	playlistId: number,
	isMyPlaylist: boolean,
	accessToken: string,
) => {
	const url = `/api/carts/${cartId}/playlists`;
	return postRequest({
		url: url,
		data: {
			playlistId,
			isMyPlaylist,
		},
		accessToken: accessToken,
	});
};

export const getCartsRequest = async ({
	accessToken,
	cartId,
	withItems = false,
	withErrorHandling,
}: {
	accessToken: string;
	cartId?: number;
	withItems?: boolean;
	withErrorHandling?: boolean;
}) => {
	const url = cartId ?
		`/api/carts?items=${withItems}&id=${cartId}` :
		`/api/carts?items=${withItems}`;
	return getRequest(
		{
			url: url,
			accessToken: accessToken,
		},
		withErrorHandling,
	);
};

export const getCartsQuery = (accessToken: string) => {
	return {
		cacheTime: Infinity,
		stateTime: Infinity,
		queryKey: ["carts"],
		queryFn: async () => {
			const { data } = await getCartsRequest({
				accessToken,
				withErrorHandling: false,
			});
			return data;
		},
	};
};

export const deleteCartItemsRequest = async (
	cartId: number,
	items: number[],
	accessToken: string,
) => {
	const url = `/api/carts/${cartId}/items`;
	return deleteRequest({
		url: url,
		data: {
			items: items,
		},
		accessToken: accessToken,
	});
};

export const updateCartItemsRequest = async (
	cartId: number,
	items: number[],
	data: any,
	accessToken: string,
) => {
	const url = `/api/carts/${cartId}/items`;
	return patchRequest({
		url: url,
		data: {
			data: data,
			items: items,
		},
		accessToken: accessToken,
	});
};

export const postCartRequest = async (
	params: Record<string, any>,
	accessToken: string,
) => {
	return postRequest({
		url: MY_URLS.MY_CARTS,
		data: params,
		accessToken: accessToken,
		apiBase: API_BASES.api4,
	});
};

export const patchCartRequest = async (
	cartId: number,
	params: Record<string, any>,
	accessToken: string,
) => {
	const url = `${MY_URLS.MY_CARTS}${cartId}/`;
	return patchRequest({
		url: url,
		data: params,
		accessToken: accessToken,
		apiBase: API_BASES.api4,
	});
};

export const deleteCartRequest = async (
	cartId: number,
	accessToken: string,
) => {
	const url = `${MY_URLS.MY_CARTS}${cartId}/`;
	return deleteRequest({
		url: url,
		accessToken: accessToken,
		apiBase: API_BASES.api4,
	});
};

export const getCartCouponRequest = async (
	cartId: number,
	code: string,
	accessToken: string,
) => {
	const url = `${MY_URLS.MY_CARTS}${cartId}/?coupon=${code}`;
	return getRequest({
		url: url,
		accessToken: accessToken,
		apiBase: API_BASES.api4,
	});
};

export const getMyAccountRequest = async ({
	accessToken,
	location,
	withErrorHandling,
}: {
	accessToken: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = "/my/account/";
	return getRequest(
		{
			url: url,
			accessToken: accessToken,
			apiBase: API_BASES.api4,
			location,
		},
		withErrorHandling,
	);
};

export const getMyAccountQuery = ({
	accessToken,
	enabled = true,
	location,
}: {
	accessToken: string;
	enabled?: boolean;
	location?: LocationParams;
}) => ({
	queryKey: ["/my/account/"],
	queryFn: async () => {
		const { data } = await getMyAccountRequest({
			accessToken: accessToken || "",
			location,
			withErrorHandling: false,
		});
		return data as Account;
	},
	enabled: enabled,
});

export const getMyBillingRequest = async ({
	accessToken,
	location,
	withErrorHandling,
}: {
	accessToken: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = "/my/billing/";
	return getRequest(
		{
			url: url,
			accessToken: accessToken,
			apiBase: API_BASES.api4,
			location,
		},
		withErrorHandling,
	);
};

export const getMyBillingQuery = ({
	accessToken,
	enabled = true,
	location,
}: {
	accessToken: string;
	enabled: boolean;
	location?: LocationParams;
}) => ({
	cacheTime: 0,
	queryKey: ["/my/billing/"],
	queryFn: async () => {
		const { data } = await getMyBillingRequest({
			accessToken: accessToken || "",
			location,
			withErrorHandling: false,
		});
		return data;
	},
	enabled: enabled,
});

export const patchMyAccountRequest = async (data: object, accessToken: string) => {
	return patchRequest({
		url: MY_URLS.MY_ACCOUNT,
		apiBase: API_BASES.api4,
		data,
		accessToken: accessToken,
	});
};

export const postMyDjProfileRequest = async (
	data: {
		bio: string;
		enabled: boolean;
		is_indexed: boolean;
		name?: string;
		slug?: string;
		soundcloud_mode: boolean;
	},
	accessToken: string,
) => {
	const url = "/my/dj-profile/";
	return postRequest({
		url: url,
		apiBase: API_BASES.api4,
		data: data,
		accessToken: accessToken,
	});
};
export const patchMyDjProfileRequest = async (
	data: { name?: string; slug?: string },
	accessToken: string,
) => {
	const url = "/my/dj-profile/";
	return patchRequest({
		url: url,
		apiBase: API_BASES.api4,
		data: data,
		accessToken: accessToken,
	});
};

export const djProfileSlugCheck = async (
	params: { slug: string },
	accessToken: string,
) => {
	const url = `/my/dj-profile/slug-check/?slug=${params.slug}`;
	return getRequest({
		url: url,
		accessToken: accessToken,
		apiBase: API_BASES.api4,
	});
};

export const postCartRepurchaseItemsRequest = async (
	cartId: number,
	items: PurchaseItem[],
	accessToken: string,
) => {
	const url = `/api/carts/${cartId}/items`;
	return postRequest({
		url: url,
		data: {
			items: items,
		},
		accessToken: accessToken,
	});
};
