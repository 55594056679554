import styled from "styled-components";

export const Item = styled.div`
  width: 100%;
  flex-direction: row;
  background: #141414;
  padding: 0px 8px 0px 0px;
  border-radius: 4px;
  display: flex;

  span.icon {
    padding: 16px;
    border-radius: 4px 0px 0px 4px;

    svg {
      path {
        fill: black;
      }
    }

    &.success {
      background: #01ff95;
    }

    &.error {
      background: #cf222e;
    }
  }

  span.msg {
    padding: 16px;
    width: 100%;

    font-family: ${(props) => props.theme.fonts.aeonik};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;

    color: #ffffff;
  }

  span.close {
    padding: 16px;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 60px;
  left: calc(100% - 370px);
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 999;

  @media ${(props) => props.theme.device.sm} {
    left: calc(100% - 385px);
  }

  @media ${(props) => props.theme.device.md} {
    left: calc(100% - 385px);
  }

  @media ${(props) => props.theme.device.xl} {
    left: calc(100% - 385px);
  }

  @media ${(props) => props.theme.device.xxl} {
    left: initial;
    right: calc((100% - 1371px) / 2);
  }
`;
