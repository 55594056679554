import { AUDIO_FORMAT_IDS, DEFAULT_AUDIO_FORMAT } from "@lib/constants";
import { LOCAL_STORAGE_COUPON_KEY } from "@lib/constants/storage-keys";
import {
	deleteCartItemRequest,
	deleteCartItemsRequest,
	getCartCouponRequest,
	getMyAccountRequest,
	postCartChartRequest,
	postCartItemRequest,
	postCartPlaylistRequest,
	postCartRepurchaseItemsRequest,
	updateCartItemsRequest,
} from "@lib/network/my";
import { removeLocalStorage } from "@lib/utils";
import { Cart, CartDetails, PurchaseItem } from "@models/Cart";
import { Release } from "@models/release";
import { Track } from "@models/track";
import { AxiosError } from "axios";
import { Session } from "next-auth";
import { createQueries } from "./queries";
import { ActionResult, CartQueries, CartState } from "./types";
import { couponNotifications, getErrorMessage } from "./util";

export const createActions = (
	state: CartState,
	session: Session | null,
	dispatch: React.Dispatch<React.SetStateAction<CartState>>,
	refetchCarts: () => void,
	getSingleCartWithItems: (cartId: number, itemType?: "track" | "release") => void,
) => ({
	createCartQueries: (
		carts: Record<number, Cart>,
		cartDetails: Record<number, CartDetails>,
	): CartQueries => {
		return createQueries(carts, cartDetails);
	},
	getCartState: (cartId: number) => state.cartDetails[cartId],
	addTrackToCart: async (
		cartId: number,
		track: Track,
		audioFormatId?: number,
		purchaseTypeId?: number,
		sourceTypeId?: number,
	): Promise<ActionResult> => {
		const accessToken = session?.token.accessToken || "";
		const itemTypeId = 1;

		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}

		let audioFormat = audioFormatId || DEFAULT_AUDIO_FORMAT.id;
		const purchaseType = purchaseTypeId || 1;
		const sourceType = sourceTypeId || 6;

		if (!audioFormatId) {
			const profile = await getMyAccountRequest({
				accessToken,
			});
			let audioFormatPreference = profile.data?.preferences?.audio_format_id;
			if (
				!AUDIO_FORMAT_IDS.map((item) => item.id).includes(audioFormatPreference)
			)
				audioFormatPreference = DEFAULT_AUDIO_FORMAT.id;
			audioFormat = audioFormatPreference;
		}

		const data = await postCartItemRequest(
			cartId,
			track.id,
			itemTypeId,
			audioFormat,
			purchaseType,
			sourceType,
			accessToken,
		);
		let message = "Ok";
		if (data.success) {
			await getSingleCartWithItems(cartId);
		} else {
			message = getErrorMessage(data.data);
		}
		return { success: data.success, message: message, data: data.data };
	},

	addReleaseToCart: async (
		cartId: number,
		release: Release,
		audioFormatId?: number,
		purchaseTypeId?: number,
		sourceTypeId?: number,
	): Promise<ActionResult> => {
		const itemTypeId = 2;
		const accessToken = session?.token.accessToken || "";

		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}

		let audioFormat = audioFormatId || DEFAULT_AUDIO_FORMAT.id;
		const purchaseType = purchaseTypeId || 1;
		const sourceType = sourceTypeId || 6;

		if (!audioFormatId) {
			const profile = await getMyAccountRequest({
				accessToken,
			});

			audioFormat =
				profile.data?.preferences?.audio_format_id || DEFAULT_AUDIO_FORMAT.id;
		}

		const data = await postCartItemRequest(
			cartId,
			release.id,
			itemTypeId,
			audioFormat,
			purchaseType,
			sourceType,
			accessToken || "",
		);
		let message = "Ok";
		if (data.success) {
			await getSingleCartWithItems(cartId);
		} else {
			message = getErrorMessage(data.data);
		}
		return { success: data.success, message: message, data: data.data };
	},

	addChartToCart: async (
		cartId: number,
		chartId: number,
	): Promise<ActionResult> => {
		const accessToken = session?.token.accessToken || "";
		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}

		const data = await postCartChartRequest(
			cartId,
			chartId,
			accessToken,
		);

		if (!data.success) {
			return { success: false, message: getErrorMessage(data.data) };
		}

		await getSingleCartWithItems(cartId);

		return { success: data.success, message: "Ok", data: data.data };
	},

	addPlaylistToCart: async (
		cartId: number,
		playlistId: number,
		isMyPlaylist: boolean,
	): Promise<ActionResult> => {
		const accessToken = session?.token.accessToken || "";
		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}

		const data = await postCartPlaylistRequest(
			cartId,
			playlistId,
			isMyPlaylist,
			accessToken,
		);

		if (!data.success) {
			return { success: false, message: getErrorMessage(data.data) };
		}

		await getSingleCartWithItems(cartId);

		return { success: data.success, message: "Ok", data: data.data };
	},

	removeItemFromCart: async (
		cartId: number,
		itemId: number,
	): Promise<ActionResult> => {
		const accessToken = session?.token.accessToken || "";
		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}

		let message = "Ok";

		try {
			const data = await deleteCartItemRequest(
				cartId,
				itemId,
				accessToken,
			);

			getSingleCartWithItems(cartId);
			return { success: data.success, message: message, data: data.data };
		} catch (err: unknown) {
			if (err instanceof AxiosError) {
				message = getErrorMessage(err.response?.data);
				return { success: false, message: message, data: err.response?.data };
			}
		}

		return { success: false, message: message };
	},

	removeItemsFromCart: async (
		cartId: number,
		items: number[],
	): Promise<ActionResult> => {
		const accessToken = session?.token.accessToken || "";
		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}

		const data = await deleteCartItemsRequest(
			cartId,
			items,
			accessToken,
		);
		let message = "Ok";

		if (data.success) {
			await getSingleCartWithItems(cartId);
		} else {
			message = getErrorMessage(data.data);
		}
		return { success: data.success, message: message, data: data.data };
	},
	moveCartItems: async (
		cartId: number,
		newCartId: number,
		items: number[],
	): Promise<ActionResult> => {
		const accessToken = session?.token.accessToken || "";
		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}

		const destinationCart = state.cartDetails[newCartId];
		if (!destinationCart) {
			return { success: false, message: "Destination Cart does not exist" };
		}

		const data = await updateCartItemsRequest(
			cartId,
			items,
			{
				cart: newCartId,
			},
			accessToken,
		);
		let message = "Ok";

		if (data.success) {
			await getSingleCartWithItems(cartId);
			await getSingleCartWithItems(newCartId);
		} else {
			message = getErrorMessage(data.data);
		}

		return { success: data.success, message: message, data: data.data };
	},
	updateCartItems: async (
		cartId: number,
		items: number[],
		itemType: "track" | "release" | undefined,
		params: any,
	): Promise<ActionResult> => {
		const accessToken = session?.token.accessToken || "";
		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}

		const data = await updateCartItemsRequest(
			cartId,
			items,
			params,
			accessToken,
		);
		let message = "Ok";

		if (data.success) {
			await getSingleCartWithItems(cartId, itemType);
		} else {
			message = getErrorMessage(data.data);
		}

		return { success: data.success, message: message, data: data.data };
	},

	reloadCarts: async () => {
		refetchCarts();
	},

	applyCoupon: async (cartId: number, code: string) => {
		const accessToken = session?.token.accessToken || "";
		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}

		const resp = await getCartCouponRequest(
			cartId,
			code,
			accessToken,
		);
		let message = "Ok";
		if (resp.success && resp.data) {
			const couponCode = resp.data?.summary?.price?.coupon?.code;
			const notification = couponNotifications(couponCode);

			if (notification) {
				if (notification.category === "success") {
					state.cartDetails[cartId] = resp.data;
					dispatch({ ...state });
				}
				return {
					success: notification.category === "success",
					message: notification.content,
					data: resp.data,
				};
			}
		} else {
			message = getErrorMessage(resp.data);
		}

		return { success: resp.success, message: message, data: resp.data };
	},

	removeCoupon: async (cartId: number) => {
		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}
		const message = "Ok";
		removeLocalStorage(LOCAL_STORAGE_COUPON_KEY);
		await getSingleCartWithItems(cartId);
		return { success: true, message: message };
	},

	purchaseItems: async (cartId: number, items: PurchaseItem[]) => {
		const accessToken = session?.token.accessToken || "";
		const currentCart = state.cartDetails[cartId];
		if (!currentCart) {
			return { success: false, message: "Cart does not exist" };
		}

		const data = await postCartRepurchaseItemsRequest(
			cartId,
			items,
			accessToken,
		);

		if (!data.success) {
			return { success: false, message: getErrorMessage(data.data) };
		}

		await getSingleCartWithItems(cartId);

		return { success: data.success, message: "Ok", data: data.data };
	},
});
