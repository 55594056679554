import styled from "styled-components";
import { ItemControls } from "../Lists.shared.style";

export const TrackName = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.neutrals.primary.white};

  span {
    font-weight: 400;
  }
`;

export const ListHeading = styled.div`
  height: 48px;
  border-top: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.divider1};
  padding: 10px 56px;
  font-size: 12px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  margin-bottom: 8px;
`;

export const ItemActions = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-left: 36px;

  left: 100%;
  transition: left 0.2s ease-in-out;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  color: ${(props) => props.theme.colors.neutrals.primary.white};
  overflow-x: hidden;

  @supports (overflow-x: clip) {
    overflow-x: clip;
  }

  div.actions {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 8px;
    @media ${(props) => props.theme.device.xl} {
      justify-content: flex-start;
    }
    span.unavailable {
      padding: 20px;
    }
  }

  div.cart-actions {
    margin-top: 8px;
  }
`;

export const Spacer = styled.div`
  width: 24px;
`;

export const ItemNumber = styled.span`
  display: flex;
  justify-content: center;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  width: 36px;
  min-width: 36px;
`;

export const ItemMeta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 125px);
  height: 100%;
  position: absolute;
  left: 80px;
  overflow-x: clip;
  padding-right: 1rem;
`;

export const ItemButtons = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
  width: 40px;
  border-left: 1px solid ${(props) => props.theme.colors.neutrals.primary[800]};

  &.borderless {
    border-left: none;
  }

  .tombstoned {
    opacity: 0.15;
  }

  .selectable {
    &:hover {
      opacity: 1;
    }
  }
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.neutrals.primary.graytext};

  &.current {
    background: ${(props) => props.theme.colors.neutrals.secondary.gray.active};
    ${ItemActions} {
      background: ${(props) =>
		props.theme.colors.neutrals.secondary.gray.active};
    }
  }

  &.tombstoned {
    background: ${(props) => props.theme.colors.neutrals.primary[700]};
    a {
      pointer-events: none;
    }
    &:hover {
      background: ${(props) => props.theme.colors.neutrals.primary[700]};
    }
  }

  ${ItemControls} {
    overflow: visible;
  }

  &.actions ${ItemActions} {
    left: 0;
  }

  .play-hover {
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 36px;
    min-width: 36px;
  }

  &.no-artwork {
    ${ItemMeta} {
      left: 0;
      width: calc(100% - 50px);
    }
  }

  &.force-hover {
    span.play {
      display: none;
    }

    ${ItemControls} {
      display: none;
      width: calc(100% - 50px);
    }
    &:hover {
      .play-hover {
        display: flex;
      }
      ${ItemNumber} {
        display: none;
      }

      ${ItemActions} {
        justify-content: flex-start;
        left: 138px;
        @media ${(props) => props.theme.device.xxl} {
          left: 168px;
        }
      }
    }
  }

  &.force-hover.no-artwork {
    ${ItemMeta} {
      left: 0;
      width: 100%;
    }
  }

  &.cart-list {
    ${ItemActions} {
      gap: 0px;
      justify-content: flex-end;
      div.actions {
        justify-content: center;
      }

      div.cart-actions {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }

  svg.album-cover {
    display: none;
    position: absolute;
    margin-left: 20px;
  }

  &:hover {
    background: ${(props) => props.theme.colors.neutrals.secondary.gray.active};
    svg.album-cover {
      display: inline;
    }

    ${ItemActions} {
      background: ${(props) =>
		props.theme.colors.neutrals.secondary.gray.active};
    }
  }
`;

export const Wrapper = styled.div`
  &.row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 8px;
    padding: 16px 0;
    width: 100%;

    &.panel-list {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }

    @media ${(props) => props.theme.device.lg} {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
  }

  &.column {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    padding: 16px 0;
  }

  .droppable {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const AddTrackControls = styled.div`
  padding: 0 8px;
`;

export const ItemImageWrapper = styled.div`
  width: 8rem;
`;

export const ItemImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemDataWrapper = styled.div`
  width: calc(100% - 8rem);
  display: flex;
  justify-content: space-between;
`;

export const ItemDataContainer = styled.div`
  width: 70%;
`;

export const ItemControlsContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
