import {
	CartEvent,
	CheckoutEvent,
	CouponSuccessEvent,
	FollowEvent,
	LOGGED_IN_SESSION_STORAGE_KEY,
	LoginEvent,
	ProductAddToPlaylistEvent,
	ProductArtistEvent,
	ProductClickEvent,
	ProductDetailViewEvent,
	ProductImpressionEvent,
	ProductLabelEvent,
	ProductListingEvent,
	ProductPlayEvent,
	ProductQueueEvent,
	ProductSubscribeEvent,
	PurchaseEvent,
	SocialShareEvent,
} from "@bptypes/dataLayerEvents";
import { Chart } from "@models/Chart";
import { Release } from "@models/release";
import { i18n } from "next-i18next";

export const EVENT_NAMES = {
	ADD_TO_CART: "EECaddToCart",
	FOLLOW: "follow",
	UNFOLLOW: "unfollow",
	CART_CHECKOUT: "EECcheckout",
	SUBSCRIPTION_CHECKOUT: "EECcheckout",
	PURCHASE_CHECKOUT: "EECpurchase",
	PRODUCT_LISTING: "productListingClick",
	PRODUCT_ARTIST: "productArtistClick",
	PRODUCT_LABEL: "productLabelClick",
	PRODUCT_SUBSCRIBE: "EECaddToCart",
	PRODUCT_IMPRESSION: "EECproductImpression",
	PRODUCT_DETAIL_VIEW: "EECproductDetailView",
	PRODUCT_CLICK: "EECproductClick",
	PRODUCT_PLAY: "trackPlay",
	PRODUCT_QUEUE: "addToQueue",
	PRODUCT_ADD_TO_PLAYLIST: "addToPlaylists",
	SUCCESSFUL_LOGIN: "successfulLogin",
	SUCCESSFUL_REGISTRATION: "successfulRegistration",
	COUPON_APPLY: "couponApply",
	SOCIAL_SHARE: "socialShare",
};

const MAX_PRODUCTS_PER_IMPRESSION = 24;
const DEFAULT_LANGUAGE = "en";

export function pushDataLayer<T extends object>(event: T) {
	if (!window.dataLayer) {
		window.dataLayer = [];
	}
	window.dataLayer.push({
		path: window.location.pathname,
		language: i18n?.language || DEFAULT_LANGUAGE,
		...event,
	});
}

export const pushSuccessfulLoginEvent = (eventObj: LoginEvent) => {
	// Limit this event to be fired only once per session
	const userIsLoggedIn = sessionStorage.getItem(LOGGED_IN_SESSION_STORAGE_KEY);
	if (!userIsLoggedIn) {
		pushDataLayer({
			...eventObj,
			event: EVENT_NAMES.SUCCESSFUL_LOGIN,
		});
		sessionStorage.setItem(LOGGED_IN_SESSION_STORAGE_KEY, "true");
	}
};

export const pushSuccessfulRegistrationEvent = (eventObj: LoginEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.SUCCESSFUL_REGISTRATION,
	});

export const pushSubscriptionCheckoutEvent = (eventObj: CheckoutEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.SUBSCRIPTION_CHECKOUT,
	});

export const pushPurchaseCheckoutEvent = (eventObj: PurchaseEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.PURCHASE_CHECKOUT,
	});

export const pushProductListingEvent = (eventObj: ProductListingEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.PRODUCT_LISTING,
	});

export const pushProductArtistEvent = (eventObj: ProductArtistEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.PRODUCT_ARTIST,
	});

export const pushProductLabelEvent = (eventObj: ProductLabelEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.PRODUCT_LABEL,
	});

export const pushProductSubscribeEvent = (eventObj: ProductSubscribeEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.PRODUCT_SUBSCRIBE,
	});

export const pushProductImpressionEvent = (eventObj: ProductImpressionEvent) => {
	const { impressions } = eventObj.ecommerce;

	if (impressions.length <= MAX_PRODUCTS_PER_IMPRESSION) {
		pushDataLayer({
			...eventObj,
			event: EVENT_NAMES.PRODUCT_IMPRESSION,
		});
		return;
	}

	// ? If there are more than MAX_PRODUCTS_PER_IMPRESSION products in the impression array,
	// ? we need to split the impression into multiple events.
	const numberOfImpressions = Math.ceil(impressions.length / MAX_PRODUCTS_PER_IMPRESSION);

	for (let i = 0; i < numberOfImpressions; i++) {
		const start = i * MAX_PRODUCTS_PER_IMPRESSION;
		const end = start + MAX_PRODUCTS_PER_IMPRESSION;
		const condensedImpression = impressions.slice(start, end);

		pushDataLayer({
			...eventObj,
			event: EVENT_NAMES.PRODUCT_IMPRESSION,
			ecommerce: {
				impressions: condensedImpression,
			},
		});
	}
};

export const pushProductClickEvent = (eventObj: ProductClickEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.PRODUCT_CLICK,
	});

export const pushProductPlayEvent = (eventObj: ProductPlayEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.PRODUCT_PLAY,
	});

export const pushProductQueueEvent = (eventObj: ProductQueueEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.PRODUCT_QUEUE,
	});

export const pushProductAddToPlaylistEvent = (eventObj: ProductAddToPlaylistEvent) =>
	pushDataLayer({
		...eventObj,
		event: EVENT_NAMES.PRODUCT_ADD_TO_PLAYLIST,
	});

export const dataLayerReleaseImpression = (
	release: Release,
	index: number,
	location?: string,
) => ({
	id: release.id.toString(),
	name: release.name,
	position: index + 1,
	label: release?.label?.name || null,
	list: location || null,
	artists: release?.artists?.map((artist) => artist.name).join(",") || null,
	remixers: release?.remixers?.map((remixer) => remixer.name).join(",") || null,
	genres: release?.genres?.map((genre) => genre.name).join(",") || null,
	subGenres: null,
	preOrder: release.pre_order,
	type: "product",
	category: "Releases",
	variant: "album",
	price: release?.price?.value || null,
	imageUrl: release?.image.uri || null,
});

export const dataLayerChartImpression = (
	item: Chart,
	index: number,
	location?: string,
) => ({
	id: item.id.toString(),
	name: item.name,
	position: index + 1,
	label: null,
	list: location || null,
	artists: item?.artist?.name || null,
	remixers: null,
	genres: item?.genres?.map((genre) => genre.name).join(",") || null,
	subGenres: null,
	preOrder: null,
	type: "product",
	category: "Chart",
	variant: "chart",
	price: item?.price?.value || null,
	imageUrl: item?.image?.uri || null,
});

export const pushCouponSuccessEvent = (eventObj: CouponSuccessEvent) => pushDataLayer({
	...eventObj,
	event: EVENT_NAMES.COUPON_APPLY,
});

export const pushProductDetailViewEvent = (eventObj: ProductDetailViewEvent) => pushDataLayer({
	...eventObj,
	event: EVENT_NAMES.PRODUCT_DETAIL_VIEW,
});

export const pushAddToCartEvent = (eventObj: CartEvent) => pushDataLayer({
	...eventObj,
	event: EVENT_NAMES.ADD_TO_CART,
});

export const pushCartCheckoutEvent = (eventObj: CheckoutEvent) => pushDataLayer({
	...eventObj,
	event: EVENT_NAMES.CART_CHECKOUT,
});

export const pushSocialShareEvent = (eventObj: SocialShareEvent) => pushDataLayer({
	...eventObj,
	event: EVENT_NAMES.SOCIAL_SHARE,
});

export const pushFollowEvent = (eventObj: FollowEvent) => pushDataLayer({
	...eventObj,
});
