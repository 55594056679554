import { DownloadIcon } from "@components/core/icons";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { ItemLoader } from "@components/loaders";
import { postMessage } from "@components/notifications";
import { ENCODING_STATUSES } from "@lib/constants";
import { filenamePresets } from "@lib/constants/audio-format";
import { useSessionContext } from "@lib/context/session";
import logger from "@lib/logger";
import { getDownloadLink, postReDownloadEvent } from "@lib/network/downloads";
import { createFilename, triggerSingleFileDownload } from "@lib/utils";
import { DownloadError } from "@lib/utils/errors";
import { Track } from "@models/track";
import { theme } from "@styles/theme";
import { useTranslation } from "next-i18next";
import React, { MouseEventHandler, useState } from "react";
import { Tooltip } from "../Tooltip";
import { ItemLoaderWrapper } from "./DownloadButtons.style";

interface Props {
	track: Track;
	encodeStatus: string | undefined | null;
	filenamePattern?: string;
	disabled?: boolean;
	shouldMarkDownloadAsComplete?: boolean;
}

const CompletedDownload = () => (
	<button disabled>
		<SpriteIcon id="download-finished" width="13" height="9" />
	</button>
);

const DownloadButton: React.FC<Props> = ({
	track,
	encodeStatus,
	disabled,
	filenamePattern = filenamePresets[1],
	shouldMarkDownloadAsComplete = false,
}) => {
	const [downloadStarted, setDownloadStarted] = useState(false);
	const [loadingDownload, setLoadingDownload] = useState(false);
	const { getAccessToken } = useSessionContext();
	const { t } = useTranslation("translation");
	const accessToken = getAccessToken();

	const isPreOrder = track.pre_order;

	const handleDownload: MouseEventHandler = async () => {
		try {
			setLoadingDownload(true);
			if (!track.initial_download) {
				await postReDownloadEvent({ track_ids: track.id }, accessToken);
			}

			const { order_item_download_id } = track;
			if (!order_item_download_id) {
				return postMessage({
					type: "error",
					message: t("Warning.DownloadUnavailable"),
				});
			}
			const response = await getDownloadLink(
				{ order_item_download_id },
				accessToken,
			);
			const filename = createFilename(
				track,
				response.data.extension,
				filenamePattern,
			);
			const downloadUrl = response.data.download_url;
			await triggerSingleFileDownload(filename, downloadUrl);
			setDownloadStarted(true);
		} catch (err) {
			let message = "Single download error";
			if (err instanceof DownloadError) {
				message = "Download failed on triggerSingleFileDownload";
			}
			postMessage({
				type: "error",
				message: t("Error.Download"),
			});
			logger.error(err, message);
		} finally {
			setLoadingDownload(false);
		}
	};

	let Element: JSX.Element;
	switch (encodeStatus) {
		case ENCODING_STATUSES.COMPLETE:
			Element = (
				<button onClick={handleDownload} disabled={disabled}>
					<DownloadIcon disabled={disabled} />
				</button>
			);
			break;
		case ENCODING_STATUSES.ERROR:
			Element = (
				<Tooltip text={t("Error.EncodingError")} position="top-left">
					<SpriteIcon
						id="info"
						stroke={theme.colors.semantic.danger}
						fill={theme.colors.semantic.danger}
					/>
				</Tooltip>
			);
			break;
		case ENCODING_STATUSES.ENCODING:
		case null:
		default:
			Element = (
				<Tooltip text={t("Processing")} position="top-left">
					<SpriteIcon id="encoding" />
				</Tooltip>
			);
	}

	if (shouldMarkDownloadAsComplete) {
		return <CompletedDownload />;
	}

	return isPreOrder ?
			(
				<Tooltip text={t("Collection.Preorder")}>
					<SpriteIcon id="preorder" />
				</Tooltip>
			) :
			(
				<>
					{loadingDownload && (
						<ItemLoaderWrapper disabled data-testid="loader-wrapper">
							<ItemLoader />
						</ItemLoaderWrapper>
					)}
					{downloadStarted && !loadingDownload && <CompletedDownload />}
					{!downloadStarted && !loadingDownload && Element}
				</>
			);
};

export default DownloadButton;
