import Marquee from "@components/Marquee";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { useSessionContext } from "@lib/context/session";
import { cls } from "@lib/css";
import { theme } from "@styles/theme";
import Image from "next/image";
import Link from "next/link";
import { Fragment, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import {
	Artwork,
	CartMeta,
	Exclusive,
	ItemControls,
	MetaRow,
	MoreButton,
} from "../Lists.shared.style";
import {
	AddTrackControls,
	Item,
	ItemActions,
	ItemButtons,
	ItemMeta,
	ItemNumber,
	ListHeading,
	Spacer,
	TrackName,
	Wrapper,
} from "./TracksList.style";

import { TableTypes } from "@bptypes/tables";
import Button from "@components/Button";
import ImageFallback from "@components/core/Image/ImageFallback";
import {
	AddToCart,
	AddToPlaylist,
	AddToQueue,
	Play,
	Tooltip,
} from "@components/interaction";
import { DownloadButton } from "@components/interaction/DownloadButtons";
import DownloadAllButton from "@components/interaction/DownloadButtons/DownloadAllButton";
import { Control } from "@components/interaction/Play/Play.style";
import ReDownloadButton from "@components/interaction/ReDownloadButton/ReDownloadButton";
import { UpgradeToCart } from "@components/interaction/UpgradeToCart";
import { ItemLoader, PageLoader } from "@components/loaders";
import { FinalRedownloadConfirmationModal } from "@components/modals/FinalRedownloadConfirmationModal";
import {
	ArtistNames,
	renderArtistNames,
} from "@components/shared/Artists/ArtistNames";
import LabelLink from "@components/shared/Labels/LabelLink";
import { TrackAddIndicator } from "@components/tables/LibraryTable/LibraryTable.style";
import {
	AUDIO_FORMATS,
	AUDIO_FORMAT_IDS,
	TRACK_PLACEHOLDER,
} from "@lib/constants";
import { filenamePresets, renderAudioFormatName } from "@lib/constants/audio-format";
import { MAX_FREE_DOWNLOADS } from "@lib/constants/subscriptions";
import {
	useCollectionActions,
	useCollectionState,
} from "@lib/context/collection";
import { usePlayerActions, usePlayerState } from "@lib/context/player";
import { useStrictDroppable } from "@lib/hooks/useStrictDroppable";
import { getMyAccountRequest } from "@lib/network/my";
import { checkIfUserHasSubscription, updateSelectedTracks } from "@lib/utils";
import { dynamicImageUrl } from "@lib/utils/dynamicImageUrl";
import { CartItem } from "@models/Cart";
import { Audioformat } from "@models/audio-format";
import { Track } from "@models/track";
import {
	pushProductClickEvent,
	pushProductImpressionEvent,
} from "@utils/dataLayer";
import isEmpty from "lodash/isEmpty";
import { Trans, useTranslation } from "next-i18next";
import {
	DragDropContext,
	Draggable,
	DraggableProvidedDragHandleProps,
	Droppable,
	OnDragEndResponder,
} from "react-beautiful-dnd";
import { ChartsTrackListItem } from "./TrackListItems/ChartsTrackListItem";

interface Props {
	isLoading?: boolean;
	tracks?: Track[];
	cartTracks?: CartItem<Track>[];
	showNumbers?: boolean;
	showArtwork?: boolean;
	forceHover?: boolean;
	isPanelList?: boolean;
	direction?: "row" | "column";
	itemCartActions?: (cartItem: CartItem<Track>) => JSX.Element;
	selectedItems?: number[];
	onItemSelectionChange?: (itemId: number, selected: boolean) => void;
	audioFormats?: Record<number, Audioformat>;
	isCollection?: boolean;
	isDownloads?: boolean;
	isAddTrackModal?: boolean;
	isPaginated?: boolean;
	isThankYou?: boolean;
	chartAddTrackLoading?: number[];
	chartAddedTracks?: number[];
	chartAddTrack?: (trackId: number) => void;
	trackListType?: TableTypes;
	chartDeleteTrack?: (trackId: number) => void;
	draggable?: boolean;
	onDragEnd?: OnDragEndResponder;
	dataTestId?: string;
	location?: string;
	page?: number;
	perPage?: number;
	overallEncodingStatus?: string;
}

interface RenderTrackProps {
	track: Track;
	index: number;
	dragHandleProps?: DraggableProvidedDragHandleProps;
	t: (key: string) => string;
	isSubscribed: boolean;
	isPaginated: boolean;
	perPage: number;
	page: number;
	trackListType?: TableTypes;
	location?: string;
	dataTestId: string;
	chartDeleteTrack?: (trackId: number) => void;
	currentTrack?: Track;
	controls?: number;
	setControls: (id: number | undefined) => void;
	showArtwork?: boolean;
	forceHover?: boolean;
	isCartList?: boolean;
	isAddTrackModal?: boolean;
	exclusive: string;
	handlePlayTrackClick: (track: Track) => void;
	isCollection: boolean;
	handleSelectTrack: (e: React.ChangeEvent<HTMLInputElement>, track: Track) => void;
	queueTracks?: Track[];
	showNumbers?: boolean;
	filteredTracks: Track[];
	onItemSelectionChange?: (itemId: number, selected: boolean) => void;
	selectedItems?: number[];
	handleTrackClick: (track: Track, index: number) => void;
	audioFormats: Record<number, Audioformat>;
	setFinalRedownloadModalCallback: (callback: (() => MouseEventHandler) | null) => void;
	itemCartActions?: (cartItem: CartItem<Track>) => JSX.Element;
	isDownloads?: boolean;
	filenamePattern: string;
	chartAddTrack?: (trackId: number) => void;
	chartAddTrackLoading?: number[];
	chartAddedTracks?: number[];
}

const FALLBACK_URL = TRACK_PLACEHOLDER.href;

const RenderTrack = ({
	track,
	index,
	dragHandleProps,
	t,
	isSubscribed,
	isPaginated,
	perPage,
	page,
	trackListType,
	location,
	dataTestId,
	chartDeleteTrack,
	currentTrack,
	controls,
	setControls,
	showArtwork,
	forceHover,
	isCartList,
	isAddTrackModal,
	exclusive,
	handlePlayTrackClick,
	isCollection,
	handleSelectTrack,
	queueTracks,
	showNumbers,
	filteredTracks,
	onItemSelectionChange,
	selectedItems,
	handleTrackClick,
	audioFormats,
	setFinalRedownloadModalCallback,
	itemCartActions,
	isDownloads,
	filenamePattern,
	chartAddTrack,
	chartAddTrackLoading = [],
	chartAddedTracks = [],
}: RenderTrackProps) => {
	const actionsContainerRef = useRef<HTMLDivElement>(null);
	const shouldMarkDownloadAsComplete =
		!isSubscribed && !track.initial_download && track.received_status;

	const currentNumberOfDownloads = track.order_item_download_try_count || 0;
	const trackIndex = isPaginated ? perPage * (page - 1) + index : index;
	const hasOneDownloadAttemptLeft = !isSubscribed && (currentNumberOfDownloads === MAX_FREE_DOWNLOADS - 1);
	const hasNoMoreDownloadAttempts = !isSubscribed && currentNumberOfDownloads >= MAX_FREE_DOWNLOADS;

	// Assume track is enabled if the flag is not set
	const enabled =
		track.enabled || track.enabled === undefined || track.enabled === null;
	const isUsingUpgradedAudioFormat =
		track.audio_format &&
		AUDIO_FORMAT_IDS.filter(
			(item) =>
				item.name === AUDIO_FORMATS.aiff || item.name === AUDIO_FORMATS.wav,
		)
			.map((item) => item.id)
			.includes(track?.audio_format?.id);

	return trackListType === "CHART" ?
			(
				<ChartsTrackListItem
					key={`list-track-${track.id}`}
					location={location}
					data-testid={dataTestId}
					track={track}
					index={index}
					chartDeleteTrack={chartDeleteTrack}
					dragHandleProps={dragHandleProps}
				/>
			) :
			(
				<Item
					key={`list-track-${track.id}`}
					data-testid={dataTestId}
					className={cls(
						currentTrack && currentTrack.id === track.id ? "current" : undefined,
						controls && controls === track.id ? "actions" : undefined,
						!showArtwork ? "no-artwork" : undefined,
						forceHover ? "force-hover" : undefined,
						isCartList ? "cart-list" : undefined,
						!enabled ? "tombstoned" : undefined,
					)}
				>
					{showArtwork && !isAddTrackModal && (
						<Link
							href={`/release/${track.release.slug}/${track.release.id}`}
							prefetch={false}
							title={track.release.name}
							className="artwork"
						>
							<Artwork>
								<Image
									src={dynamicImageUrl(track.release.image?.uri, "sm")}
									alt={track.release.name}
									width={80}
									height={80}
								/>
								{track.exclusive && <Exclusive>{exclusive.toUpperCase()}</Exclusive>}
							</Artwork>
						</Link>
					)}

					{isAddTrackModal && (
						<Control onClick={() => handlePlayTrackClick(track)}>
							<Artwork>
								<ImageFallback
									src={track.release.image?.uri}
									srcSize="sm"
									alt={track.release.name}
									width={80}
									height={80}
									fallbackSrc={FALLBACK_URL}
									blurDataURL={FALLBACK_URL}
									placeholder="blur"
								/>
								{track.exclusive && <Exclusive>{exclusive.toUpperCase()}</Exclusive>}
							</Artwork>
							<SpriteIcon
								id="track-play-inline"
								width="11"
								height="16"
								className="album-cover"
							/>
						</Control>
					)}

					<ItemMeta>
						{isCollection ?
								(
									<ItemNumber>
										{track.pre_order ?
												(
													<div style={{ display: "inline-block" }}>
														<SpriteIcon id="pre-order" />
													</div>
												) :
												(
													enabled && !isAddTrackModal && (
														<input
															type="checkbox"
															value={track.id}
															onChange={(e) => handleSelectTrack(e, track)}
															checked={queueTracks?.some(
																(selectedTrack: Track) => selectedTrack.id === track.id,
															)}
														/>
													)
												)}
									</ItemNumber>
								) :
							null}
						{!showNumbers && !isCollection && !isCartList ? <Spacer /> : null}
						{!isCartList && showNumbers && (
							<>
								<ItemNumber>{trackIndex + 1}</ItemNumber>
								<div className="play-hover">
									<Play tracks={[track]} virtualQueue={filteredTracks} />
								</div>
							</>
						)}
						{isCartList && track.cart_item_data && onItemSelectionChange && (
							<ItemNumber
								onClick={() => {
									onItemSelectionChange(
										track.cart_item_data?.id || 0,
										!selectedItems?.some((i: number) => i === track.cart_item_data?.id),
									);
								}}
							>
								{selectedItems?.some((i: number) => i === track.cart_item_data?.id) ?
										(
											<SpriteIcon id="checkbox-selected" />
										) :
										(
											<SpriteIcon id="checkbox" />
										)}
							</ItemNumber>
						)}
						<MetaRow>
							<Link
								href={`/track/${track.slug}/${track.id}`}
								prefetch={false}
								title={track.name}
								onClick={() => handleTrackClick(track, index)}
							>
								<Marquee overlayElementSelector={`[data-overlayfor="track-${track.id}"]`}>
									<TrackName>
										{track.name} <span>{track.mix_name}</span>{" "}
									</TrackName>
								</Marquee>
							</Link>
							{track.artists?.length > 0 && (
								<Marquee overlayElementSelector={`[data-overlayfor="track-${track.id}"]`}>
									<ArtistNames>
										{renderArtistNames(track.artists, { location })}
									</ArtistNames>
								</Marquee>
							)}
							{!isCartList && (
								<Marquee overlayElementSelector={`[data-overlayfor="track-${track.id}"]`}>
									<LabelLink label={track.release.label} location={location} withMarquee />
								</Marquee>
							)}
							{isCartList && (
								<CartMeta>
									<span>{track.cart_item_data?.price?.total.display}</span>
									<span>
										<Trans>
											{renderAudioFormatName(
												Object.values(audioFormats),
												track.cart_item_data?.audio_format_id,
												track.purchase_type_id,
											)}
										</Trans>
									</span>
								</CartMeta>
							)}
						</MetaRow>
						<ItemActions data-overlayfor={`track-${track.id}`} ref={actionsContainerRef}>
							<div className="actions">
								<span className="play">
									<Play tracks={[track]} virtualQueue={filteredTracks} />
								</span>
								<span className="queue">
									<AddToQueue tracks={[track]} />
								</span>
								<span className="playlist">
									<AddToPlaylist tracks={[track]} />
								</span>
								{isCollection && (
									<span>
										<ReDownloadButton
											track={track}
											buttonState={!track.received_status ? "ADDED" : "ADD"}
											disabled={hasNoMoreDownloadAttempts}
											onClick={
												hasOneDownloadAttemptLeft ?
														(handleDownload) => { setFinalRedownloadModalCallback(() => handleDownload); } :
													undefined
											}
										/>
									</span>
								)}

								{!isCartList && !isCollection && (
									<span className="card">
										<AddToCart location={location} track={track} />
									</span>
								)}
								{
									!isCartList && isCollection && !isUsingUpgradedAudioFormat && !track?.pre_order && (
										<span className="card">
											<UpgradeToCart upgrade={track} />
										</span>
									)
								}
							</div>
							{
								isCartList && !isCollection && itemCartActions && track.cart_item_data && (
									<div className="cart-actions">
										{itemCartActions(track.cart_item_data)}
									</div>
								)
							}
						</ItemActions>
					</ItemMeta>
					{!isAddTrackModal && (
						<ItemControls>
							<ItemButtons>
								{!isDownloads && (
									<>
										<MoreButton className={!enabled ? "tombstoned" : ""}>
											<Play
												disabled={!enabled}
												tracks={[track]}
												virtualQueue={filteredTracks}
											/>
										</MoreButton>
										{!enabled ?
												(
													<span className="info">
														<Tooltip text={t("Collection.Unavailable")} position="left">
															<SpriteIcon
																className={!enabled ? "tombstoned selectable" : ""}
																id="info"
																width="16"
																height="16"
																stroke={theme.colors.neutrals.primary.white}
																fill={theme.colors.neutrals.primary.white}
															/>
														</Tooltip>
													</span>
												) :
												(
													<MoreButton
														data-testid="trackslist-item-more-button"
														onClick={() => {
															controls === track.id ?
																setControls(undefined) :
																setControls(track.id);
														}}
													>
														<SpriteIcon id="dots" />
													</MoreButton>
												)}
									</>
								)}
								{isDownloads && (
									<DownloadButton
										track={track}
										encodeStatus={track.encode_status_name}
										filenamePattern={filenamePattern}
										shouldMarkDownloadAsComplete={shouldMarkDownloadAsComplete}
									/>
								)}
							</ItemButtons>
						</ItemControls>
					)}

					{isAddTrackModal && chartAddTrack !== undefined && (
						<AddTrackControls>
							{chartAddTrackLoading.includes(track.id) ?
									(
										<TrackAddIndicator>
											<span className="arrow">
												<ItemLoader />
											</span>
										</TrackAddIndicator>
									) :
								chartAddedTracks.includes(track.id) ?
										(
											<TrackAddIndicator>
												<span className="arrow">
													<SpriteIcon id="check-circle" />
												</span>
											</TrackAddIndicator>
										) :
										(
											<Button
												data-testid="add-to-chart-button"
												type="primary"
												onClick={() => chartAddTrack(track.id)}
											>
												{t("Actions.Add")}
											</Button>
										)}
						</AddTrackControls>
					)}
				</Item>
			);
};

const TracksList: React.FC<Props> = ({
	isLoading,
	tracks = [],
	cartTracks,
	showNumbers,
	showArtwork,
	forceHover,
	isPanelList,
	direction = "row",
	itemCartActions,
	selectedItems,
	onItemSelectionChange,
	audioFormats = {},
	isCollection = false,
	isDownloads = false,
	isAddTrackModal = false,
	isPaginated = false,
	isThankYou = false,
	chartAddTrackLoading = [],
	chartAddedTracks = [],
	chartAddTrack,
	trackListType,
	chartDeleteTrack,
	draggable,
	onDragEnd,
	dataTestId = "tracks-list-item",
	location,
	page = 1,
	perPage = 0,
	overallEncodingStatus,
}) => {
	const [finalRedownloadModalCallback, setFinalRedownloadModalCallback] = useState<(() => MouseEventHandler) | null>(null);
	const [controls, setControls] = useState<number | undefined>();
	const [filenamePattern, setFilenamePattern] = useState("");
	const [selectedTracks, setSelectedTracks] = useState<Track[]>([]);
	const [areTracksDownloaded, setAreTracksDownloaded] = useState(false);
	const { currentTrack } = usePlayerState();
	const { getAccessToken, getIsSessionValid, session } = useSessionContext();
	const { playTrack, addTracks } = usePlayerActions();
	const { playlist_queue: queueTracks } = useCollectionState();
	const { addTracksToPlaylist } = useCollectionActions();
	const accessToken = getAccessToken();
	const isSessionValid = getIsSessionValid();
	const [dndEnabled] = useStrictDroppable(false);
	const isSubscribed = checkIfUserHasSubscription(session?.introspect);
	const { t } = useTranslation("translation");
	const exclusive = `${t("Exclusive")}`;

	useEffect(() => {
		(async () => {
			// This request is only needed if the component is being used in the downloads view,
			// as we need to fetch the filenamePattern that the user has set.
			if (!isDownloads || !isSessionValid) return;
			const account = (await getMyAccountRequest({ accessToken })).data;
			setFilenamePattern(
				account.preferences?.filename_convention || filenamePresets[1],
			);
		})();
	}, [accessToken, isDownloads]);

	const downloadAllTracks = tracks.filter((track) => !track.pre_order);

	const filteredTracks = cartTracks ?
		cartTracks
			.filter((t) => t.item !== undefined)
			.map((c) => {
				c.item.cart_item_data = c;
				c.item.purchase_type_id = c.purchase_type_id;
				return c.item;
			}) :
		tracks;

	useEffect(() => {
		// Disabled Thank You page Download All button if tracks have been downloaded
		if (isThankYou && downloadAllTracks.length > 0) {
			const downloadedTracks = downloadAllTracks.filter((track) => {
				return track.received_status === true;
			});
			if (downloadedTracks.length === downloadAllTracks.length) setAreTracksDownloaded(true);
		}
	}, [downloadAllTracks, isThankYou]);

	const getProductEventData = useCallback(
		(track: Track, index: number) => ({
			id: track.id.toString(),
			name: track.name,
			position: index + 1,
			label: track?.release?.label?.name || null,
			list: location || null,
			artists: track?.artists?.map((a) => a.name).join(", ") || null,
			remixers: track?.remixers?.map((r) => r.name).join(", ") || null,
			genres: track?.genre?.name || null,
			subGenres: track?.sub_genre?.name || null,
			preOrder: track.pre_order,
			type: "product",
			category: "Tracks",
			variant: "track",
			price: track?.price?.value || null,
			imageUrl: track?.release?.image?.uri,
		}),
		[location],
	);

	useEffect(() => {
		if (!isEmpty(filteredTracks)) {
			pushProductImpressionEvent({
				ecommerce: {
					currencyCode: filteredTracks[0]?.price?.code || "",
					impressions: filteredTracks?.map(getProductEventData),
				},
			});
		}
	}, []);

	const handleTrackClick = (track: Track, index: number) => {
		pushProductClickEvent({
			ecommerce: {
				currencyCode: track?.price?.code || "",
				click: {
					actionField: {
						list: location || "",
					},
					products: [getProductEventData(track, index)],
				},
			},
		});
	};

	const handleSelectTrack = (
		e: React.ChangeEvent<HTMLInputElement>,
		track: Track,
	) => {
		const isChecked = e.target.checked;
		const updatedTracks = updateSelectedTracks(
			isChecked,
			isCollection,
			track,
			queueTracks,
			selectedTracks,
		);
		if (isCollection) {
			addTracksToPlaylist(updatedTracks);
		}
		setSelectedTracks(updatedTracks);
	};

	const isCartList = cartTracks !== undefined;

	const handlePlayTrackClick = (track: Track) => {
		playTrack(track);
		setTimeout(() => addTracks([track]), 100);
	};

	const inheritedTrackProps = {
		t,
		isSubscribed,
		isPaginated,
		perPage,
		page,
		trackListType,
		location,
		dataTestId,
		chartDeleteTrack,
		currentTrack,
		controls,
		setControls,
		showArtwork,
		forceHover,
		isCartList,
		isAddTrackModal,
		exclusive,
		handlePlayTrackClick,
		isCollection,
		handleSelectTrack,
		queueTracks,
		showNumbers,
		filteredTracks,
		onItemSelectionChange,
		selectedItems,
		handleTrackClick,
		audioFormats,
		setFinalRedownloadModalCallback,
		itemCartActions,
		isDownloads,
		filenamePattern,
		chartAddTrack,
		chartAddTrackLoading,
		chartAddedTracks,
	};

	const hasSelectedTracks = selectedTracks.length > 0;

	return (
		<>
			<FinalRedownloadConfirmationModal
				show={!!finalRedownloadModalCallback}
				onClose={() => { setFinalRedownloadModalCallback(null); }}
				onConfirmReDownload={() => {
					if (finalRedownloadModalCallback) {
						finalRedownloadModalCallback();
						setFinalRedownloadModalCallback(null);
					}
				}}
			/>
			{isDownloads && (
				<DownloadAllButton
					tracks={hasSelectedTracks ? selectedTracks : downloadAllTracks}
					disabled={downloadAllTracks.length === 0 || isLoading || areTracksDownloaded}
					filenamePattern={filenamePattern}
					fullButton={true}
					overallEncodingStatus={hasSelectedTracks ? undefined : overallEncodingStatus}
				/>
			)}
			{
				isCollection && !isAddTrackModal ?
						(
							<ListHeading>
								<div>{t("TrackName")}</div>
								<div>{t("ArtistsRemixers")}</div>
							</ListHeading>
						) :
					null
			}
			{isLoading ?
					(
						<PageLoader />
					) :
					(
						<Wrapper
							className={cls(direction, isPanelList ? "panel-list" : undefined)}
						>
							{draggable && onDragEnd ?
									(
										<DragDropContext onDragEnd={onDragEnd}>
											{dndEnabled && (
												<Droppable droppableId="chart-tracks-droppable">
													{(provided) => (
														<div
															className="droppable"
															ref={provided.innerRef}
															{...provided.droppableProps}
														>
															{filteredTracks.map((track, index) => (
																<Draggable
																	draggableId={`chart-track-${track.id}`}
																	index={index}
																	key={`chart-track-${track.id}`}
																>
																	{(innerProvided) => (
																		<div
																			ref={innerProvided.innerRef}
																			{...innerProvided.draggableProps}
																		>
																			<RenderTrack
																				track={track}
																				index={index}
																				dragHandleProps={
																					innerProvided.dragHandleProps || undefined
																				}
																				{...inheritedTrackProps}
																			/>
																		</div>
																	)}
																</Draggable>
															))}
															{provided.placeholder}
														</div>
													)}
												</Droppable>
											)}
										</DragDropContext>
									) :
									(
										filteredTracks.map((track, index) => (
											<Fragment key={`list-track-${track.id}`}>
												<RenderTrack track={track} index={index} {...inheritedTrackProps} />
											</Fragment>
										))
									)}
						</Wrapper>
					)}
		</>
	);
};

export default TracksList;
