import ImageFallback from "@components/core/Image/ImageFallback";
import {
	Table,
	TableCell,
	TableData,
	TableHeader,
	TableRow,
} from "@components/core/Table";
import { AddToCart, AddToPlaylist, AddToQueue, Play, Tooltip } from "@components/interaction";

import LabelLink from "@components/shared/Labels/LabelLink";
import { TRACK_PLACEHOLDER } from "@lib/constants";
import { usePlayerState } from "@lib/context/player";
import { cls } from "@lib/css";
import { useStrictDroppable } from "@lib/hooks/useStrictDroppable";
import { PlaylistItem } from "@models/Playlists";
import { theme } from "@styles/theme";
import Link from "next/link";
import React, { useEffect, useState } from "react";

import Marquee from "@components/Marquee";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { ArtistNames, renderArtistNames } from "@components/shared/Artists/ArtistNames";
import { useTranslation } from "next-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
	ArtworkAndControls,
	Exclusive,
	ReleaseName,
	TrackNo,
	Wrapper,
} from "./PlaylistTracksTable.style";

const IMG_WIDTH = TRACK_PLACEHOLDER.image.width;
const IMG_HEIGHT = TRACK_PLACEHOLDER.image.height;
const FALLBACK_URL = TRACK_PLACEHOLDER.href;

interface Props {
	playlistItems: PlaylistItem[];
	showActionControls?: boolean;
	onItemDelete?: (id: number) => void;
	onItemsReorder?: (items: PlaylistItem[]) => void;
	loading?: boolean;
	scrollable?: boolean;
	location?: string;
}

const PlaylistTracksTable: React.FC<Props> = ({
	playlistItems,
	showActionControls = false,
	onItemDelete,
	onItemsReorder,
	loading = false,
	scrollable = false,
	location,
}) => {
	const { t } = useTranslation("translation");
	const { currentTrack } = usePlayerState();
	const [items, setItems] = useState<PlaylistItem[]>([]);

	useEffect(() => {
		setItems(playlistItems);
	}, [playlistItems]);

	const reorderTracks = (result: any) => {
		const currentIndex = result.source.index;
		const nextIndex = result.destination.index;
		const didNotMove = currentIndex === nextIndex;

		if (!result.destination || didNotMove) return;

		const ordered = Array.from(items);
		const [removed] = ordered.splice(currentIndex, 1);
		ordered.splice(nextIndex, 0, removed);

		setItems(ordered);

		if (onItemsReorder) {
			onItemsReorder(ordered);
		}
	};

	const deleteItem = (itemId: number) => {
		setItems((items) => items.filter((item) => item.id !== itemId));
		if (onItemDelete) {
			onItemDelete(itemId);
		}
	};

	const [dndEnabled] = useStrictDroppable(false);

	const renderItem = (
		item: PlaylistItem,
		index: number,
		draggableProps?: any,
	) => {
		const { tombstoned, track } = item;
		return (
			<TableRow
				key={`playlist-ite-${index}`}
				className={cls(
					"row",
					currentTrack && currentTrack.id === track.id ? "current" : undefined,
					tombstoned ? "tombstoned" : undefined,
				)}
			>
				<TableCell className="controls">
					<ArtworkAndControls>
						<Link
							href={`/release/${track.release.slug}/${track.release.id}`}
							prefetch={false}
							title={track.release.name}
							className="artwork"
						>
							<ImageFallback
								src={track.release.image?.uri}
								srcSize="sm"
								alt={track.release.name}
								width={IMG_WIDTH}
								height={IMG_HEIGHT}
								fallbackSrc={FALLBACK_URL}
								blurDataURL={FALLBACK_URL}
								placeholder="blur"
							/>
							{track.exclusive && <Exclusive>EXCLUSIVE</Exclusive>}
						</Link>
						<TrackNo>{index + 1}</TrackNo>
						<span className={tombstoned ? "tombstoned" : "fade"}>
							<Play
								tracks={[track]}
								virtualQueue={items.map((item) => item.track)}
								disabled={tombstoned}
							/>
						</span>
						<span className={tombstoned ? "tombstoned" : "fade"}>
							<AddToQueue tracks={[track]} disabled={tombstoned} />
						</span>
						<span className={tombstoned ? "tombstoned" : "fade"}>
							<AddToPlaylist tracks={[item.track]} disabled={tombstoned} />
						</span>
					</ArtworkAndControls>
				</TableCell>
				<TableCell className="cell title">
					<div className="container">
						<Link
							href={`/track/${track.slug}/${track.id}`}
							prefetch={false}
							title={track.name}
						>
							<Marquee>
								<ReleaseName>
									{track.name} <span>{track.mix_name}</span>{" "}
								</ReleaseName>
							</Marquee>
						</Link>
						{track.artists && track.artists.length > 0 && (
							<ArtistNames>
								<Marquee>
									{renderArtistNames(track.artists, { location })}
								</Marquee>
							</ArtistNames>
						)}
					</div>
				</TableCell>
				<TableCell className="cell label">
					<LabelLink label={track.release.label} location={location} withMarquee />
					{track.remixers && track.remixers.length > 0 && (
						<ArtistNames>
							{renderArtistNames(track.remixers, { location })}
						</ArtistNames>
					)}
				</TableCell>
				<TableCell className="cell bpm">
					<Link
						href={`/genre/${track.genre.slug}/${track.genre.id}`}
						prefetch={false}
						title={track.genre.name}
					>
						<Marquee>
							{track.genre.name}
							{
								track?.sub_genre && track.sub_genre.name && (
									<> | {track.sub_genre.name}</>
								)
							}
						</Marquee>
					</Link>
					<div>
						{track.bpm} BPM - {track.key?.name}
					</div>
				</TableCell>
				<TableCell className="cell date">{track.new_release_date}</TableCell>
				<TableCell
					className={cls(
						"cell card fade",
						!showActionControls ? "no-controls" : undefined,
					)}
				>
					{tombstoned ?
							(
								<span className="info">
									<Tooltip text={t("Collection.Unavailable")} position="left">
										<SpriteIcon
											id="info"
											width="16"
											height="16"
											stroke={theme.colors.neutrals.primary.white}
											fill={theme.colors.neutrals.primary.white}
										/>
									</Tooltip>
								</span>
							) :
							(
								<span className="add-to-cart">
									<AddToCart location={location} track={track} />
								</span>
							)}
					{showActionControls && (
						<>
							<button className="reorder" {...draggableProps}>
								<SpriteIcon id="re-order" />
							</button>
							<button
								onClick={() => {
									deleteItem(item.id);
								}}
							>
								<SpriteIcon id="close-x" width="19" height="18" />
							</button>
						</>
					)}
				</TableCell>
			</TableRow>
		);
	};

	const TrackListItem = ({
		item,
		index,
	}: {
		item: PlaylistItem;
		index: number;
	}) => (
		<Draggable
			draggableId={`playlist-item-${item.id}`}
			index={index}
			key={`playlist-item-${item.id}`}
		>
			{(provided, { isDragging, isDropAnimating }) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					data-is-dragging={(isDragging && !isDropAnimating).toString()}
				>
					{renderItem(item, index, provided.dragHandleProps)}
				</div>
			)}
		</Draggable>
	);

	const TracksList = React.memo(({ items }: { items: PlaylistItem[] }) => {
		return (
			<>
				{items.map((item, index) => (
					<TrackListItem item={item} index={index} key={item.id} />
				))}
			</>
		);
	});
	TracksList.displayName = "PlaylistSortableTrackList";

	return (
		<Wrapper className={cls("numbers", loading ? "loading" : undefined)}>
			<Table>
				<TableHeader className="row">
					<TableCell className="controls">&nbsp;</TableCell>
					<TableCell className="title">
						{`${t("Title")} / ${t(
							"Artists",
						)}`}
					</TableCell>
					<TableCell className="label">
						{`${t("Label.Title")} / ${t(
							"Remixers",
						)}`}
					</TableCell>
					<TableCell className="bpm">
						{`${t("Genre")} / BPM & ${t(
							"Key",
						)}`}
					</TableCell>
					<TableCell className="date">{t("Released")}</TableCell>
					<TableCell className="card">&nbsp;</TableCell>
				</TableHeader>
				{showActionControls ?
						(
							<DragDropContext onDragEnd={reorderTracks}>
								<TableData className={cls(scrollable ? "scrollable" : "")}>
									{dndEnabled && (
										<Droppable droppableId="playlist-tracks-droppable">
											{(provided) => (
												<div
													className="list-wrapper"
													ref={provided.innerRef}
													{...provided.droppableProps}
												>
													<TracksList items={items} />
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									)}
								</TableData>
							</DragDropContext>
						) :
						(
							<TableData className={cls(scrollable ? "scrollable" : "")}>
								{items.map((item, index) => renderItem(item, index))}
							</TableData>
						)}
			</Table>
		</Wrapper>
	);
};

export default PlaylistTracksTable;
