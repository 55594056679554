import {
	ELEMENT_SPACING,
	HEADER_HEIGHT,
	SIDEBAR_DESKTOP_WIDTH,
} from "@lib/css";
import styled from "styled-components";

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.colors.neutrals.primary[800]};
  min-height: calc(100vh - ${HEADER_HEIGHT});
  display: flex;
  justify-content: center;
`;

export const Main = styled.main`
  padding: ${ELEMENT_SPACING} 0;
  width: 100%;
  max-width: 1420px;
  display: flex;
`;

interface MainContentProps {
	$showSidebar: boolean;
}

export const MainContent = styled.div<MainContentProps>`
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: start;

  @media ${(props) => props.theme.device.lg} {
    width: calc(
      100% - ${(props) => (props.$showSidebar ? SIDEBAR_DESKTOP_WIDTH : "0px")}
    );
  }
`;
