import Button from "@components/Button";
import { Box } from "@components/core/Box";
import { Modal } from "@components/core/Modal";
import { PlaylistForm } from "@components/forms/PlaylistForm";
import { ItemLoader } from "@components/loaders";
import { postMessage } from "@components/notifications";
import { PlaylistTable } from "@components/tables/PlaylistTable";
import { PlaylistTracksTable } from "@components/tables/PlaylistTracksTable";
import { useSessionContext } from "@lib/context/session";
import { getChartRequest } from "@lib/network/charts";
import {
	getMyPlaylistsInfiniteQuery,
	getMyPlaylistTrackIds,
	postPlaylistItemsRequest,
	postPlaylistRequest,
} from "@lib/network/playlists";
import { getReleaseRequest } from "@lib/network/releases";
import { getChartTracksRequest, getTracksRequest } from "@lib/network/tracks";
import { PaginatedResponse } from "@models/generics";
import { Playlist, PlaylistItem } from "@models/Playlists";
import { Track } from "@models/track";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { pushProductAddToPlaylistEvent } from "@utils/dataLayer";
import { useTranslation } from "next-i18next";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { DuplicateTracksModal } from "../DuplicateTracksModal";
import {
	ButtonWrapper,
	LoaderWrapper,
	PlaylistLoaderWrapper,
	Wrapper,
} from "./AddToPlaylistModal.style";

interface Props {
	show?: boolean;
	id: number;
	tracks?: Track[];
	type: string;
	onClose: () => void;
}

const AddToPlaylistModal: React.FC<Props> = ({
	show = false,
	id,
	type,
	tracks,
	onClose,
}) => {
	const { getAccessToken, getIsSessionValid } = useSessionContext();
	const { t } = useTranslation("translation");
	const [isNewPlaylist, setIsNewPlaylist] = useState(false);
	const [playlistItems, setPlaylistItems] = useState<PlaylistItem[]>([]);
	const [selectedItems, setSelectedItems] = useState<Playlist[]>([]);
	const [initialName, setInitialName] = useState("");
	const [addLoading, setAddLoading] = useState(false);
	const [duplicateData, setDuplicatedata] = useState<any[]>([]);
	const [formErrors, setFormErrors] = useState<Record<string, Array<string>>>(
		{},
	);
	const accessToken = getAccessToken();
	const isSessionValid = getIsSessionValid({ isAnonAllowed: false });

	const {
		data: myPlaylists,
		fetchNextPage,
		isFetchingNextPage,
	} = useInfiniteQuery<PaginatedResponse<Playlist>>(
		getMyPlaylistsInfiniteQuery({
			params: { page: 1, per_page: 50 },
			accessToken,
			enabled: isSessionValid,
		}),
	);
	const queryClient = useQueryClient();

	const closeModal = () => {
		setIsNewPlaylist(false);
		setInitialName("");
		setPlaylistItems([]);
		setSelectedItems([]);
		setAddLoading(false);
		setFormErrors({});
		setDuplicatedata([]);
		onClose();
	};

	const loadResource = async () => {
		if (type === "release") {
			const resp = await getReleaseRequest({ id, accessToken });
			if (resp.success) {
				setInitialName(resp.data.name);
			}
		} else if (type === "chart") {
			const resp = await getChartRequest({ accessToken, id });
			if (resp.success) {
				setInitialName(resp.data.name);
			}
		}
	};

	const loadPlaylistItems = async () => {
		if (type === "track") {
			const items: PlaylistItem[] = [];
			tracks?.forEach((item, index) => {
				items.push({
					id: item.id,
					tombstoned: false,
					position: index + 1,
					track: item,
				});
			});
			setPlaylistItems(items);
		} else if (type === "release") {
			const resp = await getTracksRequest({ params: { release_id: id, per_page: 100 }, accessToken });

			if (resp.success) {
				const { data } = resp;
				const items: PlaylistItem[] = [];
				if (data && data.results && data.results.length > 0) {
					data.results.forEach((item: Track, index: number) => {
						items.push({
							id: item.id,
							tombstoned: false,
							position: index + 1,
							track: item,
						});
					});
				}

				setPlaylistItems(items);
			} else {
				postMessage({
					type: "error",
					message: `${t("Error.UnableToLoad", { type: t("Release") })}`,
				});
			}
		} else if (type === "chart") {
			const resp = await getChartTracksRequest({ params: { per_page: 100 }, accessToken, chartId: id });
			if (resp.success) {
				const { data } = resp;
				const items: PlaylistItem[] = [];
				if (data && data.results && data.results.length > 0) {
					data.results.forEach((item: Track, index: number) => {
						items.push({
							id: item.id,
							tombstoned: false,
							position: index + 1,
							track: item,
						});
					});
				}

				setPlaylistItems(items);
			} else {
				postMessage({
					type: "error",
					message: `${t("Error.UnableToLoad", { type: t("Charts.label") })}`,
				});
			}
		}
	};

	const handleOnNewPlaylist = async () => {
		setIsNewPlaylist(true);
	};

	const handleOnPlaylistItemDelete = async (itemId: number) => {
		const items = playlistItems.filter((item) => {
			return item.id !== itemId;
		});
		setPlaylistItems(items);
		postMessage({
			type: "success",
			message: t("Playlists.TrackRemoved"),
		});
	};

	const handleOnPlaylistItemsReorder = async (items: PlaylistItem[]) => {
		setPlaylistItems(items);
		postMessage({
			type: "success",
			message: t("Playlists.TracksReordered"),
		});
	};

	const handleOnSubmit = async (data: Record<string, string>) => {
		NProgress.start();
		const response = await postPlaylistRequest(
			data,
			accessToken,
		);
		if (!response.success) {
			setFormErrors(response.data);
		} else {
			const data = { track_ids: playlistItems.map((pi) => pi.track.id) };
			const length = playlistItems.length;

			const addResp = await postPlaylistItemsRequest(
				response.data.id,
				data,
				accessToken,
			);
			if (addResp.success) {
				postMessage({
					type: "success",
					message: `${length} ${
						length > 1 ? t("Tracks") : t("Track")
					} ${t("Playlists.AddedTo")}`,
				});
				queryClient.invalidateQueries({
					predicate: (query) => {
						const key = query.queryKey[0];
						if ((key as string).startsWith("my-playlists-")) return true;
						return false;
					},
				});
			} else {
				postMessage({
					type: "error",
					message: t("Error.UnableAddTracks"),
				});
			}

			closeModal();
		}

		NProgress.done(false);
	};

	const addTracksToPlaylists = async (playlistIds: number[], data: any) => {
		await Promise.all(
			playlistIds.map((playlistId) =>
				postPlaylistItemsRequest(playlistId, data, accessToken),
			),
		);

		const length = data.track_ids.length;

		postMessage({
			type: "success",
			message: `${length} ${length > 1 ? t("Tracks") : t("Track")} ${t("Playlists.AddedMulti", { number: `${playlistIds.length}` })}`,
		});
		queryClient.invalidateQueries({
			predicate: (query) => {
				const key = query.queryKey[0];
				if ((key as string).startsWith("my-playlists-")) return true;
				return false;
			},
		});
		closeModal();
	};

	const handleOnAddTracksToPlaylists = async () => {
		setAddLoading(true);
		const data = { track_ids: playlistItems.map((pi) => pi.track.id) };
		const playlistData: any[] = [];

		// ? Record event when tracks are added to playlist
		const eventProducts = playlistItems.map(({ track }) => ({
			name: track.name,
			label: track?.release?.label?.name,
			artists: track?.artists?.map((artist) => artist.name).join(", ") || null,
			remixers: track?.remixers?.map((remixer) => remixer.name).join(", ") || null,
			genres: track?.genre?.name,
			subGenres: track?.sub_genre?.name || null,
			preOrder: track?.pre_order || null,
		}));
		pushProductAddToPlaylistEvent({ products: eventProducts });

		// get all track ids from playlist
		const results = await Promise.all(
			selectedItems.map((playlist) =>
				getMyPlaylistTrackIds({ id: playlist.id, accessToken }),
			),
		);

		let hasDuplicates = false;

		results.forEach((ids, index) => {
			const duplicates = ids.data.tracks.some((item: any) =>
				data.track_ids.includes(item.track_id),
			);

			const playlist = selectedItems.at(index) || 0;

			playlistData.push({
				playlist: playlist,
				playlistTracks: ids.data.tracks,
				duplicates: duplicates,
			});

			if (duplicates) {
				hasDuplicates = true;
			}
		});

		if (!hasDuplicates) {
			await addTracksToPlaylists(
				selectedItems.map((p) => p.id),
				data,
			);
		} else {
			setDuplicatedata(playlistData);
		}
	};

	const handleOnAddToPlaylists = async (
		playlists: number[],
		playlistItems: PlaylistItem[],
	) => {
		setAddLoading(true);
		const data = { track_ids: playlistItems.map((pi) => pi.track.id) };
		await addTracksToPlaylists(playlists, data);
	};

	const handleOnCancel = () => {
		setIsNewPlaylist(false);
	};

	const handleOnItemSelected = (playlist: Playlist) => {
		const selected = selectedItems.map((p) => p.id).indexOf(playlist.id) > -1;
		if (selected) {
			const items = selectedItems.filter((i) => i.id !== playlist.id);
			setSelectedItems(items);
		} else {
			const items = [...selectedItems, playlist];
			setSelectedItems(items);
		}
	};

	const closeDuplicateTracksModal = () => {
		setDuplicatedata([]);
		setAddLoading(false);
	};

	const handleOnLoadMore = () => {
		fetchNextPage();
	};

	useEffect(() => {
		loadPlaylistItems();
		loadResource();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, type]);

	const pagedResults = myPlaylists?.pages?.map((page) => page.results) || [];
	const playlists: Playlist[] = pagedResults.flat(1);

	return (
		<Modal
			show={show}
			onClose={closeModal}
			header={isNewPlaylist ? t("Playlists.Create") : t("Playlists.Select")}
			footer={(
				<>
					{!isNewPlaylist && (
						<ButtonWrapper>
							<Button type="outline-secondary" onClick={closeModal}>
								{t("Actions.Cancel")}
							</Button>
							<Button
								type="primary"
								disabled={selectedItems.length === 0 || addLoading}
								onClick={handleOnAddTracksToPlaylists}
							>
								{addLoading ?
										(
											<LoaderWrapper>
												<ItemLoader />
											</LoaderWrapper>
										) :
									selectedItems.length > 1 ?
											(
												t("Playlists.AddMulti")
											) :
											(
												t("Playlists.AddTo")
											)}
							</Button>
						</ButtonWrapper>
					)}
				</>
			)}
			showClose
		>
			<Wrapper>
				{isNewPlaylist ?
						(
							<>
								<PlaylistForm
									onSubmit={handleOnSubmit}
									onCancel={handleOnCancel}
									formErrors={formErrors}
									playlist={{ name: initialName, is_public: true }}
								/>
								<Box p="4" />
								{playlistItems.length > 0 && (
									<PlaylistTracksTable
										location="Add To Playlist Modal"
										loading={playlistItems.length === 0}
										playlistItems={playlistItems || []}
										showActionControls={playlistItems.length > 1}
										onItemsReorder={handleOnPlaylistItemsReorder}
										onItemDelete={handleOnPlaylistItemDelete}
										scrollable
									/>
								)}
							</>
						) :
						(
							<>
								<PlaylistTable
									playlists={playlists}
									selectedItems={selectedItems.map((p) => p.id)}
									onNewPlaylist={handleOnNewPlaylist}
									onItemSelected={handleOnItemSelected}
									onLoadMore={handleOnLoadMore}
									scrollable
									embedded
									showCreateButton
								/>
								{isFetchingNextPage && (
									<PlaylistLoaderWrapper>
										<ItemLoader />
									</PlaylistLoaderWrapper>
								)}
							</>
						)}
				{duplicateData.length > 0 && (
					<DuplicateTracksModal
						show={duplicateData.length > 0}
						playlistData={duplicateData}
						playlistItems={playlistItems || []}
						onClose={closeDuplicateTracksModal}
						onAddToPlaylists={handleOnAddToPlaylists}
					/>
				)}
			</Wrapper>
		</Modal>
	);
};

export default AddToPlaylistModal;
