/* eslint-disable jsx-a11y/alt-text */
import { dynamicImageUrl, Size } from "@lib/utils/dynamicImageUrl";
import Image, { ImageProps } from "next/image";
import { useEffect, useState } from "react";

export interface ImageFallbackProps extends ImageProps {
	fallbackSrc: string;
	srcSize?: Size;
}

const ImageFallback = ({ src, fallbackSrc, srcSize, ...rest }: ImageFallbackProps) => {
	const [imgSrc, setImgSrc] = useState(src);

	useEffect(() => {
		setImgSrc(src);
	}, [src]);

	return (
		<Image
			{...rest}
			src={dynamicImageUrl(imgSrc, srcSize)}
			onLoadingComplete={(result) => {
				if (result.naturalWidth === 0) {
					// Broken image
					setImgSrc(fallbackSrc);
				}
			}}
			onError={() => {
				setImgSrc(fallbackSrc);
			}}
		/>
	);
};

export default ImageFallback;
