import { API_BASES } from "@lib/constants";
import { LocationParams } from "@models/facets";
import {
	deleteRequest,
	getRequest,
	patchRequest,
	postRequest,
} from "./request";

const urlPayload = (params: any) => new URLSearchParams(params);

interface GetPlaylistsParams {
	page?: number;
	per_page?: number;
}

export const getMyPlaylistsRequest = async ({
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	params: GetPlaylistsParams;
	accessToken: string | undefined;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = "/my/playlists/";
	return getRequest(
		{
			url,
			params: params,
			accessToken,
			apiBase: API_BASES.api4,
			location,
		},
		withErrorHandling,
	);
};

export const getMyPlaylistsInfiniteQuery = ({
	params,
	accessToken,
	enabled = true,
}: {
	params: GetPlaylistsParams;
	accessToken: string | undefined;
	enabled: boolean;
}) => {
	return {
		queryKey: ["my-playlists-infinite"],
		queryFn: async ({ pageParam = 1 }) => {
			const p = { ...params, page: pageParam };
			const { data } = await getMyPlaylistsRequest({
				params: p,
				accessToken,
				withErrorHandling: false,
			});
			return data;
		},
		getNextPageParam: (lastPage: any) => {
			const parts = lastPage?.page?.split("/");

			if (
				!parts ||
				parts.length !== 2 ||
				(parts.length === 2 && parts[0] === parts[1])
			) {
				return undefined;
			}

			const page = Number(parts[0]);

			if (isNaN(page)) {
				return undefined;
			}

			return page + 1;
		},
		enabled: enabled,
	};
};

export const getMyPlaylistsQuery = ({
	params,
	accessToken,
	enabled = true,
	location,
}: {
	params: GetPlaylistsParams;
	accessToken: string | undefined;
	enabled?: boolean;
	location?: LocationParams;
}) => {
	return {
		queryKey: [`my-playlists-${urlPayload(params)}`],
		queryFn: async () => {
			const { data } = await getMyPlaylistsRequest({
				params,
				accessToken,
				location,
				withErrorHandling: false,
			});
			return data;
		},
		enabled: enabled,
	};
};

export const patchPlaylistsRequest = async (
	data: Record<string, any>,
	accessToken: string | undefined,
) => {
	const url = "/my/playlists/bulk/";
	return patchRequest({
		url,
		data: data,
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const postPlaylistRequest = async (
	data: Record<string, any>,
	accessToken: string | undefined,
) => {
	const url = "/my/playlists/";
	return postRequest({
		url,
		data: data,
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const patchPlaylistRequest = async (
	id: number,
	data: Record<string, any>,
	accessToken: string | undefined,
) => {
	const url = `/my/playlists/${id}/`;
	return patchRequest({
		url,
		data: data,
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const deletePlaylistRequest = async (
	id: number,
	accessToken: string | undefined,
) => {
	const url = `/my/playlists/${id}/`;
	return deleteRequest({
		url,
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const getMyPlaylistRequest = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken: string | undefined;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `/my/playlists/${id}/`;
	return getRequest(
		{
			url,
			accessToken,
			apiBase: API_BASES.api4,
			location,
		},
		withErrorHandling,
	);
};

export const getMyPlaylistQuery = ({
	id,
	accessToken,
	enabled = true,
	location,
}: {
	id: number;
	accessToken: string | undefined;
	enabled?: boolean;
	location?: LocationParams;
}) => {
	return {
		queryKey: [`my-playlist-${id}`],
		queryFn: async () => {
			const { data } = await getMyPlaylistRequest({
				id,
				accessToken,
				location,
				withErrorHandling: false,
			});
			return data;
		},
		enabled: enabled,
	};
};

export const getMyPlaylistTracksRequest = async ({
	id,
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	params: Record<string, any>;
	accessToken: string | undefined;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `/my/playlists/${id}/tracks/`;
	return getRequest(
		{
			url,
			params: params,
			accessToken,
			apiBase: API_BASES.api4,
			location,
		},
		withErrorHandling,
	);
};

export const getMyPlaylistTracksQuery = ({
	id,
	params,
	accessToken,
	enabled = true,
	location,
}: {
	id: number;
	params: Record<string, any>;
	accessToken: string | undefined;
	enabled?: boolean;
	location?: LocationParams;
}) => {
	return {
		queryKey: [`my-playlist-${id}-${urlPayload(params)}`],
		queryFn: async ({ pageParam = 1 }) => {
			const newParams = { ...params, page: pageParam };
			const { data } = await getMyPlaylistTracksRequest({
				id,
				params: newParams,
				accessToken,
				location,
				withErrorHandling: false,
			});
			return data;
		},
		getNextPageParam: (lastPage: any) => {
			const parts = lastPage?.page?.split("/");

			if (
				!parts ||
				parts.length !== 2 ||
				(parts.length === 2 && parts[0] === parts[1])
			) {
				return undefined;
			}

			const page = Number(parts[0]);

			if (isNaN(page)) {
				return undefined;
			}

			return page + 1;
		},
		enabled: enabled,
	};
};

export const deletePlaylistItemsRequest = async (
	id: number,
	data: Record<string, any>,
	accessToken: string | undefined,
) => {
	const url = `/my/playlists/${id}/tracks/bulk/`;
	return deleteRequest({
		url,
		data: data,
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const postPlaylistItemsRequest = async (
	id: number,
	data: Record<string, any>,
	accessToken: string | undefined,
) => {
	const url = `/my/playlists/${id}/tracks/bulk/`;
	return postRequest({
		url,
		data: data,
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const patchPlaylistItemsRequest = async (
	id: number,
	data: Record<string, any>,
	accessToken: string | undefined,
) => {
	const url = `/my/playlists/${id}/tracks/bulk/`;
	return patchRequest({
		url,
		data: data,
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const getCatalogPlaylistsRequest = async (
	params: Record<string, any>,
	accessToken: string | undefined,
) => {
	const url = "/catalog/playlists/";
	return getRequest({
		url,
		params: params,
		accessToken,
		apiBase: API_BASES.api4,
	});
};

export const getCatalogPlaylistRequest = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `/catalog/playlists/${id}/`;
	return getRequest(
		{
			url,
			accessToken,
			apiBase: API_BASES.api4,
			location,
		},
		withErrorHandling,
	);
};

export const getCatalogPlaylistQuery = ({
	id,
	accessToken,
	enabled = true,
	location,
}: {
	id: number;
	accessToken?: string;
	enabled?: boolean;
	location?: LocationParams;
}) => {
	return {
		queryKey: [`catalog-playlist-${id}`],
		queryFn: async () => {
			const { data } = await getCatalogPlaylistRequest({
				id,
				accessToken,
				location,
				withErrorHandling: false,
			});
			return data;
		},
		enabled: enabled,
	};
};

export const getCatalogPlaylistTracksRequest = async ({
	id,
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	params: Record<string, any>;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `/catalog/playlists/${id}/tracks/`;
	return getRequest(
		{
			url,
			params: params,
			accessToken,
			apiBase: API_BASES.api4,
			location,
		},
		withErrorHandling,
	);
};

export const getCatalogPlaylistTracksQuery = ({
	id,
	params,
	accessToken,
	enabled = true,
	location,
}: {
	id: number;
	params: Record<string, any>;
	accessToken?: string;
	enabled?: boolean;
	location?: LocationParams;
}) => {
	return {
		queryKey: [`catalog-playlist-${id}-${urlPayload(params)}`],
		queryFn: async () => {
			const { data } = await getCatalogPlaylistTracksRequest({
				id,
				params,
				accessToken,
				location,
				withErrorHandling: false,
			});
			return data;
		},
		enabled: enabled,
	};
};

export const getMyPlaylistTrackIds = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken: string | undefined;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `/my/playlists/${id}/tracks/ids/`;
	return getRequest(
		{
			url,
			accessToken,
			apiBase: API_BASES.api4,
			location,
		},
		withErrorHandling,
	);
};

export const getMyPlaylistTrackIdsQuery = ({
	id,
	accessToken,
	enabled = true,
	location,
}: {
	id: number;
	accessToken: string | undefined;
	enabled?: boolean;
	location?: LocationParams;
}) => {
	return {
		queryKey: [`playlist-${id}`],
		queryFn: async () => {
			const { data } = await getMyPlaylistTrackIds({
				id,
				accessToken,
				location,
				withErrorHandling: false,
			});
			return data;
		},
		enabled: enabled,
	};
};

export const getCatalogPlaylistTrackIds = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken: string | undefined;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `/catalog/playlists/${id}/tracks/ids/`;
	return getRequest(
		{
			url,
			accessToken,
			apiBase: API_BASES.api4,
			location,
		},
		withErrorHandling,
	);
};

export const getCatalogPlaylistTrackIdsQuery = ({
	id,
	accessToken,
	enabled = true,
	location,
}: {
	id: number;
	accessToken: string | undefined;
	enabled?: boolean;
	location?: LocationParams;
}) => {
	return {
		queryKey: [`playlist-${id}`],
		queryFn: async () => {
			const { data } = await getCatalogPlaylistTrackIds({
				id,
				accessToken,
				location,
				withErrorHandling: false,
			});
			return data;
		},
		enabled: enabled,
	};
};
