/* eslint-disable @typescript-eslint/no-unused-vars */
import { Cart } from "@models/Cart";
import { Track } from "@models/track";
import React from "react";

const LayoutContextDefaultValue = {
	showSidebar: false,
	setShowSidebar: (value: boolean) => {},
	authenticateLink: (next: string, onSuccess?: () => void) => {},
	showCartSelector: (
		show: boolean,
		top: number,
		left: number,
		carts: Cart[],
		selectedCarts: number[],
		onSelectedCart: (cartId: number) => void,
	) => {},
	showUpgradeTrackSelector: (
		show: boolean,
		top: number,
		left: number,
		carts: Cart[],
		handleTrackUpgradeClick: (cartId: number, audioFormatId: number) => void,
	) => {},
	showAddToPlaylist: (type: string, id: number, tracks?: Track[]) => {},
};

export const LayoutContext = React.createContext(LayoutContextDefaultValue);
